export function verifyEmail(email) {
  if (!email) {
    return false;
  }

  // See https://www.regular-expressions.info/email.html
  // basically at the bottom, seems like the best tradeoff.
  // Stripe validates later as well.
  return email.match(
    /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  );
}
