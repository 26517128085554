import { Constants } from "../../constants";
import axios from "axios";

export function getPlans() {
  return axios.get(`${Constants.BACKEND_URL}/plans`).then((data) => data.data);
}

export function calculatePlanPayment(plan, from, to) {
  return axios
    .get(
      `${Constants.BACKEND_URL}/userPayments/calculatePayment?planId=${
        plan.id
      }&fromTimestamp=${from.getTime()}&toTimestamp=${to.getTime()}`
    )
    .then((data) => data.data);
}
