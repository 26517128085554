import React, { useEffect, useState } from "react";
import arrowIcon from "../../resources/images/arrow-icon.svg";
import {
  TeamUserRole,
  promoteTeamUser,
  removeUserFromTeam,
  roleToReadable,
} from "../../services/teams/teams";
import { createIcon } from "../../resources/js/blockies";

export const TeamDropdownItem = ({
  user,
  name,
  status,
  isOwner,
  canEdit,
  isSelf,
  shouldReload,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState(status);

  const userBlockie = createIcon({
    seed: user.User?.wallet_address || user.User?.email || "unknown",
  });

  function changeRole(newRole) {
    promoteTeamUser({ teamUserId: user.id, newRole: newRole })
      .then((success) => {
        shouldReload();
      })
      .catch((err) => {
        shouldReload();
      });
  }

  useEffect(() => {
    setRole(status);
  }, [status]);

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCloseOpen = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleCloseOpen);
    }
    return () => window.removeEventListener("click", handleCloseOpen);
  }, [isOpen]);

  function handleRemoveUser() {
    removeUserFromTeam({ teamUserId: user.id })
      .then((success) => {
        handleToggleOpen();
        shouldReload();
      })
      .catch((error) => {
        handleToggleOpen();
      });
  }

  return (
    <div className="relative">
      <button
        onClick={
          !isOwner && canEdit
            ? (e) => {
                handleToggleOpen();
              }
            : undefined
        }
        className="flex items-center w-full gap-3 px-4 py-3"
      >
        <img
          className="w-10 h-10 rounded-[50%]"
          src={userBlockie.toDataURL()}
          alt="user"
        />
        <div className="text-left">
          <div className="text-medium">{name}</div>
          <div className="opacity-50">{`${roleToReadable(role)}${
            isSelf
              ? " (You)"
              : !user.accepted
              ? " (invitation not accepted)"
              : ""
          }`}</div>
        </div>
        {!isOwner && canEdit && (
          <button className="ml-auto">
            <img
              src={arrowIcon}
              className={`transition w-4 h-4 ${isOpen ? "rotate-180" : ""}`}
              alt="arrow icon"
            />
          </button>
        )}
      </button>
      {isOpen && (
        <div className="grid w-[150px] z-10 gap-5 absolute top-0 right-0 p-5 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)] bg-white rounded-[20px]">
          <label
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="flex items-center justify-between"
          >
            {roleToReadable(TeamUserRole.EDITOR)}
            <input
              onChange={() => {
                handleToggleOpen();
                setRole(TeamUserRole.EDITOR);
                changeRole(TeamUserRole.EDITOR);
              }}
              checked={role === TeamUserRole.EDITOR}
              className="outline-none"
              type="checkbox"
            />
          </label>
          <label
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="flex items-center justify-between"
          >
            {roleToReadable(TeamUserRole.VIEWER)}
            <input
              onChange={() => {
                handleToggleOpen();
                setRole(TeamUserRole.VIEWER);
                changeRole(TeamUserRole.VIEWER);
              }}
              checked={role === TeamUserRole.VIEWER}
              className="outline-none"
              type="checkbox"
            />
          </label>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveUser();
            }}
            className="text-left"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};
