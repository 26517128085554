import { Constants } from "../../constants";
import axios from "axios";
import { teamIdOrDefault } from "../teams/teams";

export function createProject({
  teamId,
  name,
  description,
  corsOrigins,
  allowedIps,
  bannedIps,
}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/projects`,
        JSON.stringify({
          teamId: teamId,
          name: name,
          description: description,
          cors_origins: corsOrigins,
          allowed_ips: allowedIps,
          banned_ips: bannedIps,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function getProjects({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(`${Constants.BACKEND_URL}/projects?teamId=${teamId}`)
      .then((data) => data.data);
  });
}

export function updateProject({
  apiKey,
  teamId,
  name,
  description,
  corsOrigins,
  allowedIps,
  bannedIps,
}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    const bodyObject = {
      teamId: teamId,
    };
    if (name) {
      bodyObject.name = name;
    }
    if (name) {
      bodyObject.description = description;
    }
    if (name) {
      bodyObject.cors_origins = corsOrigins;
    }
    if (name) {
      bodyObject.allowed_ips = allowedIps;
    }
    if (name) {
      bodyObject.banned_ips = bannedIps;
    }

    return axios
      .patch(
        `${Constants.BACKEND_URL}/projects/${apiKey}?teamId=${teamId}`,
        JSON.stringify(bodyObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function deleteProject({ apiKey, teamId }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .delete(`${Constants.BACKEND_URL}/projects/${apiKey}?teamId=${teamId}`)
      .then((data) => data.data);
  });
}
