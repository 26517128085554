import React, { useEffect, useState } from "react";
import pen from "../../resources/images/pen-icon.svg";
import close from "../../resources/images/close-icon.svg";
import check from "../../resources/images/check-icon.svg";
import faRepeat from "../../resources/icons/fa-repeat-solid.svg";
import LeaveTeamModal from "./LeaveTeamModal";
import { TeamUserRole, editTeam } from "../../services/teams/teams";
import { CustomLink } from "../Link/CustomLink";
import { TeamItem } from "./TeamItem";

export const TeamList = ({
  handleOpenCreateModal,
  acceptedTeams,
  allTeams,
  isCurrentTeam,
  handleTeamSwitch,
  shouldReload,
  firstInvitedTeam,
  declineTeamClicked,
  acceptTeamClicked,
}) => {
  return (
    <>
      <CustomLink
        onClick={handleOpenCreateModal}
        className="h-fit"
        mode="azure"
        clickableButton
      >
        create team
      </CustomLink>
      <div className="grid h-fit gap-7 font-avenir">
        <div className="font-medium opacity-50">All teams</div>
        <div className="grid gap-7">
          {acceptedTeams(allTeams)
            .sort((left, right) => (isCurrentTeam(left) ? -1 : 1))
            .map((team, i) => (
              <TeamItem
                key={team.id}
                editable={
                  team.TeamUser?.role === TeamUserRole.OWNER ||
                  team.TeamUser?.role === TeamUserRole.EDITOR
                }
                team={team}
                isCurrentTeam={isCurrentTeam(team)}
                plan="Magic"
                handleTeamSwitch={() => {
                  handleTeamSwitch(team);
                }}
                shouldReload={shouldReload}
              />
            ))}
        </div>
      </div>
      {firstInvitedTeam() &&
        [firstInvitedTeam()].map((invitedTeam) => (
          <div
            key={invitedTeam.id}
            className="border border-lightGray xxl:block font-avenir my-auto p-5 shadow-[0px_0px_20px_0px_#F2F2F2_inset] rounded text-center"
          >
            <div className="mb-3 text-gray">You're invited to the team</div>
            <div className="mb-6 font-medium text-md line-clamp-2">
              {invitedTeam.name}
            </div>
            <div className="grid gap-5 ">
              <CustomLink
                mode="outlinedTransparent"
                clickableButton
                noActiveChange={true}
                onClick={() => {
                  declineTeamClicked(invitedTeam);
                }}
              >
                Decline
              </CustomLink>
              <CustomLink
                mode="dark"
                clickableButton
                noActiveChange={true}
                onClick={() => {
                  acceptTeamClicked(invitedTeam);
                }}
              >
                Accept
              </CustomLink>
            </div>
          </div>
        ))}
    </>
  );
};
