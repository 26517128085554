import React, { useEffect, useState } from "react";
import arrowIcon from "../../resources/images/arrow-icon.svg";
import { CustomLink } from "../Link/CustomLink";
import { TeamDropdownItem } from "./TeamDropdownItem";
import InviteTeamModal from "./InviteTeamModal";
import {
  TeamUserRole,
  cachedSelectedTeamUser,
} from "../../services/teams/teams";
import { createIcon } from "../../resources/js/blockies";

export const TeamDropdown = ({ users, shouldReload }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const userBlockies = users.map((user) => {
    return createIcon({
      seed: user.User?.wallet_address || user.User?.email || "unknown",
    });
  });

  function canEdit() {
    return (
      cachedSelectedTeamUser()?.role === TeamUserRole.OWNER ||
      cachedSelectedTeamUser()?.role === TeamUserRole.EDITOR
    );
  }

  const handleCloseReplaceModal = () => {
    setIsInviteModalOpen(false);
    shouldReload();
  };

  const handleOpenReplaceModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCloseOpen = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleCloseOpen);
    }
    return () => window.removeEventListener("click", handleCloseOpen);
  }, [isOpen]);

  function truncateText(text, length) {
    if (text.length <= length) {
      return text;
    }

    return text.substr(0, length) + "\u2026";
  }

  return (
    <div
      className="relative"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <InviteTeamModal
        showReplaceModal={isInviteModalOpen}
        handleCloseReplaceModal={handleCloseReplaceModal}
      />
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleToggleOpen();
        }}
        className="px-5 flex w-full xxl:w-fit gap-3 justify-between items-center py-3 border rounded-sm border-white shadow-[0px_0px_40px_0px_#FFF_inset] bg-bgColor"
      >
        <div className="flex items-center">
          {users.map((user, i) =>
            i < 3 ? (
              <img
                key={user.id}
                className={`w-10 h-10 rounded-[50%] ${
                  users.length > 1 && "mx-[-4px]"
                }`}
                src={userBlockies[i].toDataURL()}
                alt="user"
              />
            ) : undefined
          )}
        </div>
        <div className="flex items-center gap-3">
          <span className="opacity-50">
            {users.length > 3 ? `+${users.length - 3}` : null}
          </span>
          <div>
            <img
              src={arrowIcon}
              className={`transition w-4 h-4 ${isOpen ? "rotate-180" : ""}`}
              alt="arrow icon"
            />
          </div>
        </div>
      </button>
      {isOpen && (
        <div className="xxl:absolute mt-3 xxl:mt-0 font-avenir xxl:w-[300px] grid gap-2 bg-white right-1/2 xxl:translate-x-1/2 px-3 top-[120%] py-7 rounded-[20px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)]">
          {users.map((user, i) => (
            <TeamDropdownItem
              user={user}
              key={user.id}
              name={truncateText(
                user.User?.wallet_address || user.User?.email || "unknown",
                16
              )}
              status={`${user.role}`}
              isOwner={user.role === TeamUserRole.OWNER}
              canEdit={canEdit()}
              isSelf={cachedSelectedTeamUser()?.id === user.id}
              shouldReload={shouldReload}
            />
          ))}

          {canEdit() && (
            <CustomLink
              onClick={handleOpenReplaceModal}
              clickableButton={true}
              mode="azure"
              className="w-full xl:px-[34px] xl:py-[22px]  xl:text-base"
            >
              share
            </CustomLink>
          )}
        </div>
      )}
    </div>
  );
};
