import React from "react";
import { Chart } from "react-charts";
import triangleIcon from "../../resources/images/triangle-icon.svg";

export const CustomChart = ({ data }) => {
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
      min: 1,
      max: 31,
      hardMax: 31,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "area",
        formatters: {
          scale: (datum) => {
            return `${parseFloat(
              datum < 1000
                ? datum
                : datum < 1000000
                ? datum / 1000
                : datum / 1000000
            ).toFixed(datum < 1000 ? 0 : 2)} ${
              datum < 1000 ? "" : datum < 1000000 ? "K" : "M"
            }`;
          },
          tooltip: (datum) => (
            <span>{`${parseFloat(
              datum < 1000
                ? datum
                : datum < 1000000
                ? datum / 1000
                : datum / 1000000
            ).toFixed(datum < 1000 ? 0 : 2)} ${
              datum < 1000 ? "" : datum < 1000000 ? "K" : "M"
            }`}</span>
          ),
        },
      },
    ],
    []
  );

  return (
    <Chart
      height={440}
      padding={{
        top: 2,
      }}
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        getSeriesStyle: (series) => {
          return {
            color: `url(#${series.index % 4})`,
            opacity: 0.7,
          };
        },
        renderSVG: () => (
          <svg>
            <defs>
              <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
                <stop
                  offset="0%"
                  style={{ stopColor: "white", stopOpacity: 0 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#75ebdd", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
          </svg>
        ),
        tooltip: {
          render: (props) => {
            return (
              <div className="bg-black w-full flex justify-evenly rounded px-5 py-3 sm:top-[-40px] sm:translate-x-[-58.5%] sm:relative">
                <span className="mr-2 text-gray">
                  {new Date().toLocaleString("en-us", { month: "long" })}{" "}
                  {props.focusedDatum?.primaryValue}
                </span>
                <span className="text-white">
                  {`${parseFloat(
                    props.focusedDatum?.secondaryValue < 1000
                      ? props.focusedDatum?.secondaryValue
                      : props.focusedDatum?.secondaryValue < 1000000
                      ? props.focusedDatum?.secondaryValue / 1000
                      : props.focusedDatum?.secondaryValue / 1000000
                  ).toFixed(
                    props.focusedDatum?.secondaryValue < 1000 ? 0 : 2
                  )} ${
                    props.focusedDatum?.secondaryValue < 1000
                      ? ""
                      : props.focusedDatum?.secondaryValue < 1000000
                      ? "K"
                      : "M"
                  }`}
                </span>
                <img
                  src={triangleIcon}
                  className="w-[34px] h-[15px] absolute sm:left-[50%] left-[-8px] rotate-[90deg] translate-y-1/2 sm:translate-y-0 bottom-[50%] sm:rotate-0 sm:translate-x-[-50%] sm:bottom-[-12px]"
                  alt="icon"
                />
                <i className="w-[8px] h-[8px] rounded-[100%] bg-black absolute translate-x-[-50%] translate-y-1/2 sm:translate-y-0 left-0 sm:left-[50%] bottom-[50%] sm:bottom-[-20px]" />
              </div>
            );
          },
        },
      }}
    />
  );
};
