import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const mode = {
  dark: "enabled:active:bg-black enabled:focus:bg-[#00221E] enabled:hover:bg-[#00221E] transition font-avenir bg-black rounded flex items-center justify-center uppercase font-medium text-azure disabled:opacity-70",
  light:
    "active:bg-white hover:bg-bgColor transition focus:bg-bgColor text-center flex items-center rounded border border-bgColor",
  white:
    "enabled:active:bg-white enabled:hover:bg-bgColor transition enabled:focus:bg-bgColor text-center flex items-center rounded border border-bgColor bg-white disabled:opacity-70",
  transparent:
    "active:bg-white hover:bg-bgColor transition focus:bg-bgColor flex items-center w-full rounded border border-bgColor bg-transparent",
  outlinedTransparent:
    "enabled:active:bg-white enabled:hover:bg-bgColor transition enabled:focus:bg-bgColor flex items-center w-full rounded border border-black bg-transparent disabled:opacity-70",
  gray: "enabled:active:bg-white enabled:hover:bg-bgColor transition border border-white enabled:focus:bg-bgColor bg-[#f6f6f6] flex items-center w-full rounded border border-bgColor disabled:opacity-70",
  azure:
    "enabled:active:bg-azure enabled:hover:bg-white enabled:hover:border-azure enabled:focus:border-azure border border-white transition enabled:focus:bg-white bg-azure flex items-center w-full rounded disabled:opacity-70",
};

export const CustomLink = (props) => {
  const currentMode = props.mode ? mode[props.mode] : mode.dark;
  const external = props.external === true ? true : false;
  const clickableButton = props.clickableButton === true ? true : false;
  const noChange = props.noActiveChange === true ? true : false;

  if (external) {
    return (
      <a
        href={props.to}
        className={twMerge(
          `font-avenir flex ${
            !props.icon && "justify-center"
          } items-center uppercase font-medium rounded-sm text-[14px] md:text-base px-5 py-3 md:px-7 md:py-5 ${currentMode} ${
            props.className && props.className
          } `
        )}
        target="_blank"
        rel="noreferrer"
      >
        {props.icon && (
          <img className="mr-2" src={props.icon} alt="link icon" />
        )}
        <span className="w-fit">{props.children}</span>
      </a>
    );
  }

  if (clickableButton) {
    return (
      <button
        disabled={props.disabled === true}
        onClick={props.onClick}
        className={twMerge(
          `w-full font-avenir flex ${
            !props.icon && "justify-center"
          } items-center uppercase font-medium rounded-sm text-[14px] md:text-base px-5 py-3 md:px-7 md:py-5 ${currentMode} ${
            props.className && props.className
          } `
        )}
      >
        {props.icon && (
          <img className="mr-2" src={props.icon} alt="link icon" />
        )}
        <span className="w-fit">{props.children}</span>
      </button>
    );
  }

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        twMerge(
          `font-avenir flex ${
            !props.icon && "justify-center"
          } items-center uppercase font-medium rounded-sm text-[14px] md:text-base px-5 py-3 md:px-7 md:py-5 ${currentMode} ${
            props.className && props.className
          } ` + (isActive && !noChange ? `bg-bgColor` : "")
        )
      }
    >
      {props.icon && <img className="mr-2" src={props.icon} alt="link icon" />}
      <span className="w-fit">{props.children}</span>
    </NavLink>
  );
};
