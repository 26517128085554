import { mainnet, polygon, optimism, arbitrum } from "wagmi/chains";

export const Constants = {
  BACKEND_URL: "https://mainnet.chainnodes.org",
  LANDING_PAGE_URL: "https://chainnodes.org",
  TELEGRAM_GROUP_URL: "https://t.me/chainnodes",

  RPC_BASE_URL: "chainnodes.org",

  SUPPORT_EMAIL: "support@chainnodes.org",
  SALES_EMAIL: "sales@chainnodes.org",

  GOOGLE_SIGNIN_CLIENT_ID:
    "921824909767-f7hfu0g6kngfn1earv7lr3aessf853t0.apps.googleusercontent.com",

  SUPPORTED_CHAINS: [mainnet, polygon, optimism, arbitrum],
  WALLET_CONENCT_PROJECT_ID: "ae849c09201219938d72a1f4e4cd0464",
};

export const pricingPlans = [
  {
    name: "Core",
    price: {
      montly: 0,
      yearly: 0,
    },
    link: "/",
    linkText: "try for free",
    advantages: [
      {
        name: "Compute units included, per month",
        value: "12M",
      },
      {
        name: "Compute Units per second",
        value: 25,
      },
      {
        name: "Supported chains: Ethereum, Polygon, BNB Chain",
        value: true,
      },
      {
        name: "AI-based predictive autoscaling",
        value: true,
      },
      {
        name: "HTTPS & Websockets: enabled",
        value: false,
      },
      {
        name: "Dashboard & Statistics",
        value: false,
      },
      {
        name: "Archive data",
        value: false,
      },
      {
        name: "Enterprise-grade security: JSON Web Token (JWT)",
        value: false,
      },
      {
        name: "Apps included",
        value: false,
      },
    ],
  },
  {
    name: "Developer",
    price: {
      montly: 550,
      yearly: 500,
    },
    link: "/",
    linkText: "get started",
    isPopular: true,
    advantages: [
      {
        name: "Compute units included, per month",
        value: "12M",
      },
      {
        name: "Compute Units per second",
        value: 25,
      },
      {
        name: "Supported chains: Ethereum, Polygon, BNB Chain",
        value: true,
      },
      {
        name: "AI-based predictive autoscaling",
        value: true,
      },
      {
        name: "HTTPS & Websockets: enabled",
        value: false,
      },
      {
        name: "Dashboard & Statistics",
        value: false,
      },
      {
        name: "Archive data",
        value: false,
      },
      {
        name: "Enterprise-grade security: JSON Web Token (JWT)",
        value: false,
      },
      {
        name: "Apps included",
        value: false,
      },
    ],
  },
  {
    name: "Team",
    price: {
      montly: 2750,
      yearly: 2500,
    },
    link: "/",
    linkText: "get started",
    isPopular: false,
    advantages: [
      {
        name: "Compute units included, per month",
        value: "12M",
      },
      {
        name: "Compute Units per second",
        value: 25,
      },
      {
        name: "Supported chains: Ethereum, Polygon, BNB Chain",
        value: true,
      },
      {
        name: "AI-based predictive autoscaling",
        value: true,
      },
      {
        name: "HTTPS & Websockets: enabled",
        value: false,
      },
      {
        name: "Dashboard & Statistics",
        value: false,
      },
      {
        name: "Archive data",
        value: false,
      },
      {
        name: "Enterprise-grade security: JSON Web Token (JWT)",
        value: false,
      },
      {
        name: "Apps included",
        value: false,
      },
    ],
  },
  {
    name: "Growth",
    price: {
      montly: 10.5,
      yearly: 10,
    },
    postfix: "K",
    link: "/",
    linkText: "get started",
    isPopular: false,
    advantages: [
      {
        name: "Compute units included, per month",
        value: "12M",
      },
      {
        name: "Compute Units per second",
        value: 25,
      },
      {
        name: "Supported chains: Ethereum, Polygon, BNB Chain",
        value: true,
      },
      {
        name: "AI-based predictive autoscaling",
        value: true,
      },
      {
        name: "HTTPS & Websockets: enabled",
        value: false,
      },
      {
        name: "Dashboard & Statistics",
        value: false,
      },
      {
        name: "Archive data",
        value: false,
      },
      {
        name: "Enterprise-grade security: JSON Web Token (JWT)",
        value: false,
      },
      {
        name: "Apps included",
        value: false,
      },
    ],
  },
];

export const extraAdvantages = {
  Core: [
    ["Unlimited Archival Requests", true],
    ["Basic Support via Telegram", true],
    ["Priority Support", false],
    ["Dedicated Account Manager", false],
    ["Immediate Support via Email during working hours", false],
    ["Early Access to new chains and features", false],
    ["Enterprise grade 24/7 immediate support", false],
    ["Optional dedicated nodes for heavily used nodes", false],
  ],
  Developer: [
    ["Unlimited Archival Requests", true],
    ["Basic Support via Telegram", true],
    ["Priority Support", true],
    ["Dedicated Account Manager", false],
    ["Immediate Support via Email during working hours", false],
    ["Early Access to new chains and features", false],
    ["Enterprise grade 24/7 immediate support", false],
    ["Optional dedicated nodes for heavily used nodes", false],
  ],
  Team: [
    ["Unlimited Archival Requests", true],
    ["Basic Support via Telegram", true],
    ["Priority Support", true],
    ["Dedicated Account Manager", true],
    ["Immediate Support via Email during working hours", true],
    ["Early Access to new chains and features", true],
    ["Enterprise grade 24/7 immediate support", false],
    ["Optional dedicated nodes for heavily used nodes", false],
  ],
  Growth: [
    ["Unlimited Archival Requests", true],
    ["Basic Support via Telegram", true],
    ["Priority Support", true],
    ["Dedicated Account Manager", true],
    ["Immediate Support via Email during working hours", true],
    ["Early Access to new chains and features", true],
    ["Enterprise grade 24/7 immediate support", true],
    ["Optional dedicated nodes for heavily used nodes", true],
  ],
};
