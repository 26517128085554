/* global BigInt */
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

export default function DeleteProjectModal({
  showDeleteModal,
  handleCloseDeleteModal,
  handleBackDeleteModal,
}) {
  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
      centered
      contentClassName="h-[100vh] text-white items-center justify-center flex"
    >
      <div className="bg-black text-center rounded p-7 max-w-[335px]">
        <div className="mb-4 text-lg font-bold">Delete project?</div>

        <p className="mb-8 opacity-50">
          Are you sure you want to delete your project from the team?
        </p>
        <div className="flex gap-4">
          <button
            onClick={handleBackDeleteModal}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF33]"
          >
            cancel
          </button>
          <button
            onClick={handleCloseDeleteModal}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF66]"
          >
            delete
          </button>
        </div>
      </div>
    </Modal>
  );
}

DeleteProjectModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  handleCloseDeleteModal: PropTypes.func.isRequired,
  handleBackDeleteModal: PropTypes.func.isRequired,
};
