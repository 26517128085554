import React, { useEffect, useState } from "react";
import pen from "../../resources/images/pen-icon.svg";
import close from "../../resources/images/close-icon.svg";
import check from "../../resources/images/check-icon.svg";
import faRepeat from "../../resources/icons/fa-repeat-solid.svg";
import LeaveTeamModal from "./LeaveTeamModal";
import { editTeam } from "../../services/teams/teams";

export const TeamItem = ({
  team,
  editable,
  plan,
  isCurrentTeam,
  handleTeamSwitch,
  shouldReload,
}) => {
  const [name, setName] = useState(team.name);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setName(team.name);
  }, [team]);

  function editTeamName(newName) {
    editTeam({ name: newName, teamId: team.id })
      .then((success) => {
        shouldReload();
      })
      .catch((err) => {
        shouldReload();
      });
  }

  const handleCloseReplaceModal = () => {
    setIsLeaveModalOpen(false);
  };

  const handleOpenReplaceModal = () => {
    setIsLeaveModalOpen(true);
  };

  const handleToggleIsEdit = () => {
    setIsEdit((prev) => {
      if (prev) {
        // Edit the name
        if (prev !== team.name) {
          editTeamName(name);
        }
      }

      return !prev;
    });
  };

  return (
    <>
      <LeaveTeamModal
        showReplaceModal={isLeaveModalOpen}
        handleCloseReplaceModal={handleCloseReplaceModal}
        team={team}
        shouldReload={() => {
          // Hard reload required
          window.location.reload();
        }}
      />
      <div className="flex justify-between gap-3">
        {/* Switch Team */}
        {!isCurrentTeam && (
          <button
            onClick={handleTeamSwitch}
            className="flex h-fit items-start transition hover:scale-[1.2] focus:outline-none focus:scale-[1.2] active:scale-10"
          >
            <img className="w-4 h-4 mt-2" src={faRepeat} alt="pen icon" />
          </button>
        )}
        {/* Name and Plan */}
        <div className="grid w-full gap-2">
          <div
            className={`font-medium text-md border-b ${
              isEdit ? "border-b-black" : "border-b-transparent"
            }`}
          >
            {isEdit ? (
              <input
                autoFocus
                onBlur={!isEdit ? handleToggleIsEdit : undefined}
                className="xxl:max-w-[150px] w-full bg-transparent outline-none "
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
              />
            ) : (
              <span className="line-clamp-1">{name}</span>
            )}
          </div>
          <div className="opacity-50">{plan}</div>
        </div>
        {/* Edit or Leave */}
        <button
          onClick={editable ? handleToggleIsEdit : handleOpenReplaceModal}
          className="flex h-fit items-start transition hover:scale-[1.2] focus:outline-none focus:scale-[1.2] active:scale-10"
        >
          {editable ? (
            isEdit ? (
              <img className="w-4 h-4 mt-2" src={check} alt="check icon" />
            ) : (
              <img className="w-4 h-4 mt-2" src={pen} alt="pen icon" />
            )
          ) : (
            <img className="w-4 h-4 mt-2" src={close} alt="close icon" />
          )}
        </button>
      </div>
    </>
  );
};
