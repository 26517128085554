import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../resources/images/logo.svg";
import dashboardIcon from "../../resources/images/dashboard-icon.svg";
import plansIcon from "../../resources/images/plans-icon.svg";
import supportIcon from "../../resources/images/support-icon.svg";
import telegramIcon from "../../resources/images/telegram-icon.svg";
import fireIcon from "../../resources/images/fire-icon.svg";
import menuIcon from "../../resources/images/menu-icon.svg";
import clsoeIcon from "../../resources/images/close-icon.svg";
import { CustomLink } from "../Link/CustomLink";
import { Constants } from "../../constants";
import { TeamItem } from "../Team/TeamItem";
import arrowIcon from "../../resources/images/arrow-icon.svg";
import CreateTeamModal from "../Team/CreateTeamModal";
import {
  TeamUserRole,
  acceptTeamInvitation,
  cachedSelectedTeamUser,
  declineTeamInvitation,
  getTeams,
  setCachedSelectedTeamUser,
} from "../../services/teams/teams";
import { TeamList } from "../Team/TeamList";

export const Sidebar = (props) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [allTeams, setAllTeams] = useState([]);

  useEffect(() => {
    Promise.all([getTeams()]).then((data) => {
      setAllTeams(data[0]);
    });
  }, []);

  function shouldReload() {
    Promise.all([getTeams()]).then((data) => {
      setAllTeams(data[0]);
    });
  }

  function acceptedTeams(ofTeams) {
    return ofTeams.filter((team) => {
      return team.TeamUser?.accepted === true;
    });
  }

  function firstInvitedTeam() {
    const invitedTeams = allTeams.filter((team) => {
      return team.TeamUser?.accepted !== true;
    });

    return invitedTeams[0];
  }

  function handleTeamSwitch(team) {
    setCachedSelectedTeamUser({ teamUser: team.TeamUser });
  }

  function isCurrentTeam(team) {
    return team.id === cachedSelectedTeamUser()?.Team.id;
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    shouldReload();
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  function declineTeamClicked(team) {
    declineTeamInvitation({ teamId: team.id })
      .then((success) => {
        shouldReload();
      })
      .catch((error) => {
        shouldReload();
      });
  }

  function acceptTeamClicked(team) {
    acceptTeamInvitation({ teamId: team.id })
      .then((success) => {
        shouldReload();
      })
      .catch((error) => {
        shouldReload();
      });
  }

  return (
    <nav
      className={`${props.isMenuOpen ? "bg-bgColor" : "bg-white"} ${
        props.isTeamsOpen ? "shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)]" : ""
      } px-4 py-6 xxl:p-7 relative transition text-avenir grid grid-rows-[auto_auto] xxl:grid-rows-[auto_auto_1fr_auto]`}
    >
      <CreateTeamModal
        showReplaceModal={isCreateModalOpen}
        handleCloseReplaceModal={handleCloseCreateModal}
      />
      {props.isTeamsOpen && (
        <div className="xxl:flex hidden absolute px-7 overflow-auto py-8 flex-col gap-8 left-0 top-[108px] bottom-0 right-0 bg-bgColor">
          <TeamList
            handleOpenCreateModal={handleOpenCreateModal}
            acceptedTeams={acceptedTeams}
            allTeams={allTeams}
            isCurrentTeam={isCurrentTeam}
            handleTeamSwitch={handleTeamSwitch}
            shouldReload={shouldReload}
            firstInvitedTeam={firstInvitedTeam}
            declineTeamClicked={declineTeamClicked}
            acceptTeamClicked={acceptTeamClicked}
          />
        </div>
      )}
      <div className="flex items-center justify-between mb-8 xxl:mb-16">
        {props.isMenuOpen ? (
          <Link
            to="/"
            className="text-black text-[18px] xxl:text-lg uppercase font-avenir font-medium flex items-center"
          >
            <img
              className="mr-3 w-[24px] h-[29px] xxl:w-auto xxl:h-auto"
              src={logo}
              alt="chainnodes logo"
            />
            <span>chainnodes</span>
          </Link>
        ) : (
          <button
            onClick={props.handleTeamsMenuToggle}
            className="flex items-center justify-between gap-4 text-left font-avenir xxl:gap-10 xxl:pt-2 xxl:pb-8 xxl:border-b xxl:w-full border-b-lightGray"
          >
            <div className="grid w-full">
              <div className="text-sm font-medium sm:text-md">
                {
                  acceptedTeams(allTeams).filter((team) =>
                    isCurrentTeam(team)
                  )[0]?.name
                }
              </div>
              <div className="text-xs opacity-50 sm:text-sm">Magic</div>
            </div>
            <div className="py-[10px] order-[-1] px-3 rounded-2xl bg-[#F3F3F3] xxl:order-1 xxl:bg-transparent xxl:p-0">
              <img
                className={`transition w-4 h-4 ${
                  props.isTeamsOpen ? "rotate-180" : ""
                }`}
                src={arrowIcon}
                alt="arrow icon"
              />
            </div>
          </button>
        )}

        <button
          className={`rounded-[16px] transition flex xxl:hidden items-center justify-center h-[44px] w-[48px] ${
            props.isMenuOpen ? "bg-white" : "bg-[#f3f3f3]"
          }`}
          onClick={props.onMenuClick}
        >
          {props.isMenuOpen ? (
            <img src={clsoeIcon} alt="close icon" />
          ) : (
            <img src={menuIcon} alt="menu icon" />
          )}
        </button>
      </div>

      <ul className="xxl:w-full overflow-y-auto grid gap-6 font-avenir font-medium grid-flow-col w-fit auto-cols-[minmax(0,1fr)] xxl:grid-flow-row xxl:auto-cols-auto">
        <li className="overflow-hidden min-w-fit xxl:w-full">
          <CustomLink
            className="px-5 py-3 capitalize xxl:px-7 xxl:py-5"
            mode="transparent"
            icon={dashboardIcon}
            to="/dashboard"
          >
            Dashboard
          </CustomLink>
        </li>
        <li className="overflow-hidden min-w-fit xxl:w-full">
          <CustomLink
            className="px-5 py-3 capitalize xxl:px-7 xxl:py-5"
            mode="transparent"
            icon={plansIcon}
            to="/plans"
          >
            Plans
          </CustomLink>
        </li>
      </ul>
      <div className="hidden xxl:block font-avenir my-auto py-5 px-6 shadow-[0px_0px_20px_0px_#F2F2F2_inset] rounded text-center">
        <div className="mb-3 font-medium text-md">Upgrade to Pro</div>
        <p className="mb-6 text-gray">
          Get 1 month free and unlock all features
        </p>
        <CustomLink
          mode="dark"
          icon={fireIcon}
          to="/plans"
          noActiveChange={true}
        >
          upgrade
        </CustomLink>
      </div>
      <ul className="hidden gap-6 font-medium xxl:grid font-avenir">
        <li>
          <CustomLink
            className="capitalize"
            to={`mailto:${Constants.SUPPORT_EMAIL}`}
            external={true}
            mode="transparent"
            icon={supportIcon}
          >
            Support
          </CustomLink>
        </li>
        <li>
          <CustomLink
            className="capitalize"
            to={Constants.TELEGRAM_GROUP_URL}
            external={true}
            mode="transparent"
            icon={telegramIcon}
          >
            Channel
          </CustomLink>
        </li>
      </ul>
    </nav>
  );
};
