import { Constants } from "../../constants";
import axios from "axios";

// **** CONSTS ****

export const TeamUserRole = Object.freeze({
  OWNER: "OWNER",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
});

export function roleToReadable(nonReadableRole) {
  switch (nonReadableRole) {
    case TeamUserRole.VIEWER:
      return "Viewer";
    case TeamUserRole.EDITOR:
      return "Admin";
    case TeamUserRole.OWNER:
      return "Owner";
    default:
      return "Unknown";
  }
}

// **** TEAM CACHING ****

export const LOCAL_STORAGE_TEAMUSER_KEY = "selectedTeamUser";

export function cachedSelectedTeamUser() {
  const teamUser = localStorage.getItem(LOCAL_STORAGE_TEAMUSER_KEY);
  if (!teamUser) {
    return null;
  }

  let teamUserJson = null;
  try {
    teamUserJson = JSON.parse(teamUser);
  } catch {}

  return teamUserJson;
}

export function setCachedSelectedTeamUser({ teamUser }) {
  localStorage.setItem(LOCAL_STORAGE_TEAMUSER_KEY, JSON.stringify(teamUser));
  window.location.reload();
}

export function removeCachedSelectedTeamUser() {
  localStorage.removeItem(LOCAL_STORAGE_TEAMUSER_KEY);
  window.location.reload();
}

export async function cachedTeamUserOrFirst() {
  let cachedItem = cachedSelectedTeamUser();
  if (!cachedItem) {
    const acceptedTeams = (await getTeams()).filter((team) => {
      return team.TeamUser?.accepted === true;
    });

    cachedItem = acceptedTeams[0]?.TeamUser;
    setCachedSelectedTeamUser({ teamUser: cachedItem });
  }

  return cachedItem;
}

export async function teamIdOrDefault({ teamId } = {}) {
  if (teamId) {
    return teamId;
  }

  return (await cachedTeamUserOrFirst())?.Team?.id;
}

// **** API LAYER ****

export function getTeams() {
  return axios.get(`${Constants.BACKEND_URL}/teams`).then((data) => data.data);
}

export function createTeam({ name, invoiceEmail }) {
  const bodyObject = { name: name };
  if (invoiceEmail) {
    bodyObject.invoice_email = invoiceEmail;
  }

  return axios
    .post(`${Constants.BACKEND_URL}/teams`, JSON.stringify(bodyObject), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => data.data);
}

export async function editTeam({ teamId, name, invoiceEmail }) {
  const bodyObject = {};
  if (invoiceEmail) {
    bodyObject.invoice_email = invoiceEmail;
  }
  if (name) {
    bodyObject.name = name;
  }

  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .patch(
        `${Constants.BACKEND_URL}/teams/${teamId}`,
        JSON.stringify(bodyObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export async function getTeamUsers({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(`${Constants.BACKEND_URL}/teams/${teamId}/users`)
      .then((data) => data.data);
  });
}

export function addUserToTeam({ teamId, role, userSearch }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/teams/${teamId}/users/add`,
        JSON.stringify({ role: role, userSearch: userSearch }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function removeUserFromTeam({ teamId, teamUserId }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .delete(
        `${Constants.BACKEND_URL}/teams/${teamId}/users/${teamUserId}/remove`
      )
      .then((data) => data.data);
  });
}

export function acceptTeamInvitation({ teamId }) {
  return axios
    .patch(`${Constants.BACKEND_URL}/teams/${teamId}/acceptInvitation`)
    .then((data) => data.data);
}

export function declineTeamInvitation({ teamId }) {
  return axios
    .patch(`${Constants.BACKEND_URL}/teams/${teamId}/declineInvitation`)
    .then((data) => data.data);
}

export function promoteTeamUser({ teamId, teamUserId, newRole }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/teams/${teamId}/users/${teamUserId}/promote`,
        JSON.stringify({ role: newRole }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function getMyPlan({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(`${Constants.BACKEND_URL}/teams/${teamId}/plan`)
      .then((data) => data.data);
  });
}
