import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
// Bootstrap is unusable because of Tailwind.
// import "bootstrap/dist/css/bootstrap.min.css";
// Axios interceptors
import "./services/interceptors/index";
// WalletConnect
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { Constants } from "./constants";
import Colors from "./services/toolbox/Colors";

// **** WalletConnect Config ****
const web3ModalMetadata = {
  name: "Chainnodes App",
  description: "The Chainnodes Dashboard",
  url: "https://app.chainnodes.org/",
  icons: ["https://app.chainnodes.org/favicon.ico"],
};
const wagmiConfig = defaultWagmiConfig({
  chains: Constants.SUPPORTED_CHAINS,
  projectId: Constants.WALLET_CONENCT_PROJECT_ID,
  metadata: web3ModalMetadata,
});
createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId: Constants.WALLET_CONENCT_PROJECT_ID,
  chains: Constants.SUPPORTED_CHAINS,
  themeVariables: {
    "--w3m-z-index": 1000000,
    "--w3m-accent": Colors.primaryBlue,
  },
});
// **** END WalletConnect Config ****

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <HashRouter>
        <App />
      </HashRouter>
    </WagmiConfig>

    {/* <Web3Modal
      projectId={Constants.WALLET_CONENCT_PROJECT_ID}
      ethereumClient={ethereumClient}
      themeVariables={{
        "--w3m-z-index": "1000000",
        "--w3m-accent-color": Colors.primaryBlue,
      }}
    /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
