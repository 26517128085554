const Colors = {
  backgroundColor: "#000000",
  primaryBlue: "#4f8fea",
  secondaryPink: "#ED6A5A",

  cardBackgroundTransparent: "rgba(0, 0, 0, 0.1)",
  cardBorderGray: "rgb(64, 68, 79)",

  lightTextColorPrimary: "#ffffff",
  lightTextColorSecondary: "#d0d0d0",
};

export default Colors;
