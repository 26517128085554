/* global BigInt */

import utf8 from "utf8";

const MAX_INT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

/**
 * Represents the DaiPermitMessage object.
 * @typedef {Object} DaiPermitMessage
 *
 * @property {String} holder - The holder of the approval.
 * @property {String} spender - The wallet to be approved.
 * @property {Number} nonce - The nonce of the approval.
 * @property {Number | String} expiry - The expiry deadline of the approval.
 * @property {Boolean | undefined} allowed - Whether it is an allow or remove allowance approval.
 */

/**
 * Represents the ERC2612PermitMessage object.
 * @typedef {Object} ERC2612PermitMessage
 *
 * @property {String} owner - The holder of the approval.
 * @property {String} spender - The wallet to be approved.
 * @property {Number | String} value - The value to be approved.
 * @property {Number} nonce - The nonce of the approval.
 * @property {Number | String} deadline - The expiry deadline of the approval.
 */

/**
 * Represents the Domain object.
 * @typedef {Object} Domain
 *
 * @property {String} name - The name of the domain.
 * @property {String} version - The version of the domain.
 * @property {Number} chainId - The chainId.
 * @property {String} verifyingContract - The verifying contract.
 */

/**
 * Represents the RSV object. TX signature values.
 * @typedef {Object} RSV
 *
 * @property {String} r - The signature r value.
 * @property {String} s - The signature s value.
 * @property {Number} v - The signature v value.
 */

const EIP712Domain = (chainId, tokenAddress) => {
  if (
    chainId === BigInt(137) &&
    tokenAddress.toLowerCase() !==
      "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359".toLowerCase()
  ) {
    return [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "verifyingContract", type: "address" },
      { name: "salt", type: "bytes32" },
    ];
  } else {
    return [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];
  }
};

/**
 * Create the typed dai data.
 *
 * @param {DaiPermitMessage} message The permit message for dai.
 * @param {Domain} domain The domain object.
 * @param {Number} chainId The chainId.
 * @param {Number} tokenAddress The token address.
 * @returns The typed data.
 */
function createTypedDaiData(message, domain, chainId, tokenAddress) {
  const typedData = {
    types: {
      EIP712Domain: EIP712Domain(chainId, tokenAddress),
      Permit: [
        { name: "holder", type: "address" },
        { name: "spender", type: "address" },
        { name: "nonce", type: "uint256" },
        { name: "expiry", type: "uint256" },
        { name: "allowed", type: "bool" },
      ],
    },
    primaryType: "Permit",
    domain,
    message,
  };

  return typedData;
}

/**
 * Create the typed ERC2612 data.
 *
 * @param {ERC2612PermitMessage} message The ERC2612 permit message.
 * @param {Domain} domain The domain object.
 * @param {Number} chainId The chainId.
 * @param {Number} tokenAddress The token address.
 * @returns The typed data.
 */
function createTypedERC2612Data(message, domain, chainId, tokenAddress) {
  const typedData = {
    types: {
      EIP712Domain: EIP712Domain(chainId, tokenAddress),
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    },
    primaryType: "Permit",
    domain,
    message,
  };

  return typedData;
}

const NONCES_FN = "0x7ecebe00";
const GET_NONCE_FN = "0x2d0335ab";
const NAME_FN = "0x06fdde03";
const VERSION_FN = "0x54fd4d50";
const EIP712_VERSION_FN = "0xeccec5a8";
const ERC712_VERSION_FN = "0x0f7e5970";

/**
 * Return number of zeros as String.
 *
 * @param {Number} numZeros The number of zeros to return as String.
 * @returns The zeros.
 */
function zeros(numZeros) {
  return "".padEnd(numZeros, "0");
}

/**
 * Hex to UTF8.
 * @param {String} hex The hex
 * @returns The utf8
 */
function hexToUtf8(hex) {
  // if (!isHexStrict(hex))
  //     throw new Error('The parameter "'+ hex +'" must be a valid HEX string.');

  let str = "";
  let code = 0;
  hex = hex.replace(/^0x/i, "");

  // remove 00 padding from either side
  hex = hex.replace(/^(?:00)*/, "");
  hex = hex.split("").reverse().join("");
  hex = hex.replace(/^(?:00)*/, "");
  hex = hex.split("").reverse().join("");

  let l = hex.length;

  for (let i = 0; i < l; i += 2) {
    code = parseInt(hex.substr(i, 2), 16);
    // if (code !== 0) {
    str += String.fromCharCode(code);
    // }
  }

  return utf8.decode(str);
}

/**
 * Get Token Name.
 *
 * @param {PublicClient} provider The provider to use to fetch data,
 * @param {String} address The address.
 * @returns
 */
async function getTokenName(provider, address) {
  const callResp = (await provider.call({ to: address, data: NAME_FN })).data;
  return hexToUtf8(callResp.substr(130));
}

/**
 * Get Token Version.
 *
 * @param {PublicClient} provider The provider to use to fetch data,
 * @param {String} address The address.
 * @returns
 */
async function getTokenVersion(provider, address) {
  const callResp = (
    await provider.call({ to: address, data: VERSION_FN }).catch((err) => {
      return provider
        .call({ to: address, data: EIP712_VERSION_FN })
        .catch((err) => {
          return provider
            .call({ to: address, data: ERC712_VERSION_FN })
            .catch((err) => {
              // Fucking Arb USDC has no version exposed. WILL lead to issues.
              // The below is "1"
              return {
                data: "0x000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000013100000000000000000000000000000000000000000000000000000000000000",
              };
            });
        });
    })
  ).data;
  return hexToUtf8(callResp.substr(130));
}

/**
 * Get Nonce for ERC2612.
 *
 * @param {PublicClient} provider The provider to use to fetch data,
 * @param {String} tokenAddress The token address.
 * @param {String} owner The owner address to get the nonce for.
 * @returns
 */
async function getERC2612Nonce(provider, tokenAddress, owner) {
  return (
    await provider
      .call({
        to: tokenAddress,
        data: `${NONCES_FN}${zeros(24)}${owner.substr(2)}`,
      })
      .catch((err) => {
        return provider.call({
          to: tokenAddress,
          data: `${GET_NONCE_FN}${zeros(24)}${owner.substr(2)}`,
        });
      })
  ).data;
}

/**
 * Get the Domain.
 *
 * @param {PublicClient} provider The provider to call.
 * @param {String | Domain} token The token or the domain.
 * @returns {Promise<Domain>} The Domain.
 */
async function getDomain(provider, token) {
  if (typeof token !== "string") {
    return token;
  }

  const tokenAddress = token;

  const [name, chainId, version] = await Promise.all([
    getTokenName(provider, tokenAddress),
    provider.getChainId(),
    getTokenVersion(provider, tokenAddress),
  ]);

  if (
    chainId === 137 &&
    tokenAddress.toLowerCase() !==
      "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359".toLowerCase()
  ) {
    // Fucking Polygon
    const domain = {
      name,
      version: version,
      salt: `0x${zeros(62)}89`,
      // chainId: `0x${BigInt(chainId).toString(16)}`,
      verifyingContract: tokenAddress,
    };
    return domain;
  }

  /**
   * @type {Domain}
   */
  const domain = {
    name,
    version: version,
    chainId: `0x${BigInt(chainId).toString(16)}`,
    verifyingContract: tokenAddress,
  };
  return domain;
}

/**
 * Signature string to RSV object.
 * @param {String} signature The signature string.
 * @returns {RSV}
 */
function splitSignatureToRSV(signature: string) {
  const r = "0x" + signature.substring(2).substring(0, 64);
  const s = "0x" + signature.substring(2).substring(64, 128);
  const v = parseInt(signature.substring(2).substring(128, 130), 16);
  return { r, s, v };
}

/**
 * Sign the given typed data.
 * @param {WalletClient} provider The provider for signing.
 * @param {String} fromAddress The address.
 * @param {Object} typeData The typed data to sign.
 * @returns {Promise<RSV>}
 */
async function signData(provider, fromAddress, typeData) {
  // const { EIP712Domain: _unused, ...types } = typeData.types;
  // const rawSignature = await provider.signTypedData({
  //   // domain: typeData.domain,
  //   types,
  //   primaryType: typeData.primaryType,
  //   message: typeData.message,
  // });
  const rawSignature = await provider.signTypedData(typeData);

  return splitSignatureToRSV(rawSignature);
}

/**
 *
 * @param {PublicClient} provider The provider.
 * @param {WalletClient} signer The signer.
 * @param {String | Domain} token The token or Domain.
 * @param {String} holder The holder.
 * @param {String} spender The spender.
 * @param {Number | undefined} expiry The deadline.
 * @param {Number | undefined} nonce The nonce.
 * @returns {Promise<DaiPermitMessage & RSV>}
 */
export async function signDaiPermit(
  provider,
  signer,
  token,
  holder,
  spender,
  expiry,
  nonce
) {
  const tokenAddress = token.verifyingContract || token;

  /**
   * @type {DaiPermitMessage}
   */
  const message = {
    holder,
    spender,
    nonce: nonce
      ? nonce
      : await getERC2612Nonce(provider, tokenAddress, holder),
    expiry: expiry || MAX_INT,
    allowed: true,
  };

  const domain = await getDomain(provider, token);
  const typedData = createTypedDaiData(
    message,
    domain,
    BigInt(domain.chainId || domain.salt),
    tokenAddress
  );
  const sig = await signData(signer, holder, typedData);

  return { ...sig, ...message };
}

/**
 *
 * @param {PublicClient} provider The provider.
 * @param {WalletClient} signer The signer.
 * @param {String | Domain} token The token or Domain.
 * @param {String} owner The holder.
 * @param {String} spender The spender.
 * @param {Number | String | undefined} value The value to approve.
 * @param {Number | undefined} deadline The deadline.
 * @param {Number | undefined} nonce The nonce.
 * @returns {Promise<ERC2612PermitMessage & RSV>}
 */
export async function signERC2612Permit(
  provider,
  signer,
  token,
  owner,
  spender,
  value,
  deadline,
  nonce
) {
  const tokenAddress = token.verifyingContract || token;

  /**
   * @type {ERC2612PermitMessage}
   */
  const message = {
    owner,
    spender,
    value: value || MAX_INT,
    nonce: nonce ? nonce : await getERC2612Nonce(provider, tokenAddress, owner),
    deadline: deadline || MAX_INT,
  };

  const domain = await getDomain(provider, token);
  const typedData = createTypedERC2612Data(
    message,
    domain,
    BigInt(domain.chainId || domain.salt),
    tokenAddress
  );
  const sig = await signData(signer, owner, typedData);

  return { ...sig, ...message };
}
