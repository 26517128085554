import React, { useEffect, useState, useCallback } from "react";
import {
  getLatestOverdueInvoice,
  getStripeCustomerPortalUrl,
  retryCryptoSubscriptionPayment,
} from "../../../services/userPayments/userPayments";
import Colors from "../../../services/toolbox/Colors";
// import ReplaceWallet from "./ReplaceWallet/ReplaceWallet";
import { getLoggedInUser } from "../../../services/users/users";
import { Button, Modal } from "flowbite-react";
import { Banner } from "flowbite-react";
import { HiX, HiOutlineExclamationCircle } from "react-icons/hi";
import { LoadingSpinner } from "../../Utilities/LoadingSpinner";
import {
  OrderDetails,
  useOrderDetailsContext,
} from "../../OrderModal/OrderDetails";

function PaymentFailure() {
  const [waiting, setWaiting] = useState(false);
  const [closeMessage, setCloseMessage] = useState(false);
  const [openInvoice, setOpenInvoice] = useState();
  const [user, setUser] = useState();

  const { showModal: showBuyPlanModal } = useOrderDetailsContext();

  useState(() => {
    getLoggedInUser().then((user) => {
      setUser(user);
    });
  });

  useState(() => {
    getLatestOverdueInvoice().then(
      (response) => {
        if (response.hasOverdueInvoice) {
          setOpenInvoice(response.invoice);
        }
      },
      [setOpenInvoice]
    );
  });

  const isLongAgo = () => {
    if (!openInvoice) {
      return false;
    }

    // First for Crypto Payments.

    if (openInvoice.cnCryptoLastAttempt) {
      return true;
    }
    if (openInvoice.collectionMethod === "send_invoice") {
      // At this point, it's ok that we don't display the alert. Our system is retrying right now.
      return false;
    }

    // Below is for Stripe automated payments. They are problematic whenever due for more than 1h.

    const dueDate = new Date(
      parseInt(openInvoice.due || new Date().getTime()) * 1000
    );

    // After 1h
    if (dueDate.getTime() < new Date().getTime() - 3600000) {
      return true;
    }

    return false;
  };

  // ---- ACTIONS ----

  const manageSubscriptionClicked = () => {
    setWaiting(true);
    getStripeCustomerPortalUrl()
      .then((data) => {
        const url = data.url;
        window.location.href = url;
      })
      .catch((err) => {
        // TODO: Show error
        console.log(err);

        setWaiting(false);
      });
  };

  const retryClicked = () => {
    setWaiting(true);

    retryCryptoSubscriptionPayment()
      .then(() => {
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO: Show error
        console.log(err);

        setWaiting(false);
      });
  };

  const replaceWalletClicked = () => {
    setWaiting(true);
    setCloseMessage(true);

    showBuyPlanModal({ amountDue: openInvoice?.amount });
  };

  // ---- VIEW ----

  return (
    <div>
      {/* Payment Failure */}
      {openInvoice && isLongAgo() && !closeMessage && (
        <>
          {openInvoice.collectionMethod === "send_invoice" && (
            <>
              <Modal
                show={!closeMessage}
                size="md"
                popup
                onClose={() => setCloseMessage(true)}
                className="bg-black"
              >
                <Modal.Header className="text-gray" />
                <Modal.Body>
                  <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray" />
                    <h3 className="mb-5 text-md font-avenir text-gray">
                      Your latest payment failed. Please make sure you have
                      enough money in your wallet and click Retry. Alternatively
                      click Replace Wallet to link a new wallet.
                    </h3>
                    <div className="flex justify-center gap-4">
                      <button
                        type="button"
                        onClick={() => {
                          retryClicked();
                        }}
                        disabled={waiting}
                        className="disabled:opacity-70 h-[20px] w-fit p-[16px] enabled:active:bg-blue enabled:active:text-blue enabled:focus:bg-white enabled:focus:text-blue enabled:hover:bg-white enabled:hover:text-blue border enabled:hover:border-blue enabled:active:border-blue transition font-avenir bg-blue rounded py-[28px] flex items-center justify-center uppercase font-medium text-white"
                      >
                        {waiting && (
                          <LoadingSpinner
                            fillColorClassName="fill-gray"
                            text="Loading..."
                          />
                        )}
                        Retry
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          replaceWalletClicked();
                        }}
                        disabled={waiting}
                        className="disabled:opacity-70 h-[20px] w-fit p-[16px] enabled:active:bg-blue enabled:active:text-blue enabled:focus:bg-white enabled:focus:text-blue enabled:hover:bg-white enabled:hover:text-blue border enabled:hover:border-blue enabled:active:border-blue transition font-avenir bg-blue rounded py-[28px] flex items-center justify-center uppercase font-medium text-white"
                      >
                        {waiting && (
                          <LoadingSpinner
                            fillColorClassName="fill-gray"
                            text="Loading..."
                          />
                        )}
                        Replace Wallet
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}

          {openInvoice.collectionMethod !== "send_invoice" && (
            <Modal
              show={!closeMessage}
              size="md"
              popup
              onClose={() => setCloseMessage(true)}
              className="bg-black"
            >
              <Modal.Header className="text-gray" />
              <Modal.Body>
                <div className="text-center">
                  <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray" />
                  <h3 className="mb-5 text-md font-avenir text-gray">
                    Your latest payment failed. Please click the link below to
                    replace your card directly through Stripe.
                  </h3>
                  <div className="flex justify-center gap-4">
                    <button
                      type="button"
                      onClick={() => {
                        manageSubscriptionClicked();
                      }}
                      disabled={waiting}
                      className="disabled:opacity-70 h-[20px] w-fit p-[16px] enabled:active:bg-blue enabled:active:text-blue enabled:focus:bg-white enabled:focus:text-blue enabled:hover:bg-white enabled:hover:text-blue border enabled:hover:border-blue enabled:active:border-blue transition font-avenir bg-blue rounded py-[28px] flex items-center justify-center uppercase font-medium text-white"
                    >
                      {waiting && (
                        <LoadingSpinner
                          fillColorClassName="fill-gray"
                          text="Loading..."
                        />
                      )}
                      Manage Subscription
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}

      {/* Payment Being Taken right now */}
      {openInvoice && !isLongAgo() && !closeMessage && (
        <>
          <Modal
            show={!closeMessage}
            size="md"
            popup
            onClose={() => setCloseMessage(true)}
            className="bg-black"
          >
            <Modal.Header className="text-gray" />
            <Modal.Body>
              <div className="text-center">
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray" />
                <h3 className="mb-5 text-md font-avenir text-gray">
                  We are processing your payment right now. This can take a few
                  minutes. Once done, you can reload the page and will see your
                  plan enabled in the Dashboard.
                </h3>
                <div className="flex justify-center gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCloseMessage(true);
                    }}
                    disabled={waiting}
                    className="disabled:opacity-70 h-[20px] w-fit p-[16px] enabled:active:bg-blue enabled:active:text-blue enabled:focus:bg-white enabled:focus:text-blue enabled:hover:bg-white enabled:hover:text-blue border enabled:hover:border-blue enabled:active:border-blue transition font-avenir bg-blue rounded py-[28px] flex items-center justify-center uppercase font-medium text-white"
                  >
                    {waiting && (
                      <LoadingSpinner
                        fillColorClassName="fill-gray"
                        text="Loading..."
                      />
                    )}
                    Got it
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default PaymentFailure;
