import { Constants } from "../../constants";
import axios from "axios";
import { teamIdOrDefault } from "../teams/teams";

export function getCurrentMonthlyStatistics({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(
        `${Constants.BACKEND_URL}/userMonthlyStatistics/current?teamId=${teamId}`
      )
      .then((data) => data.data);
  });
}
