/* global BigInt */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { CustomLink } from "../Link/CustomLink";
import closeIcon from "../../resources/images/close-icon.svg";
import { createTeam } from "../../services/teams/teams";

export default function CreateTeamModal({
  showReplaceModal,
  handleCloseReplaceModal,
}) {
  const [name, setName] = useState();
  const [isSuccess, setIsSuccess] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    createTeam({ name: name })
      .then((success) => {
        setName("");
        setIsSuccess(true);
        handleCloseReplaceModal();
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsLoading(false);
      });
  };

  const handleEmailChanged = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    return () => {
      setIsSuccess(null);
    };
  }, [showReplaceModal]);

  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showReplaceModal}
      onHide={handleCloseReplaceModal}
      centered
      contentClassName="h-[100vh] text-black items-center justify-center flex"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white relative sm:rounded p-7 w-full h-full sm:h-fit sm:max-w-[616px]"
      >
        <button
          className="absolute top-8 right-8"
          onClick={(e) => {
            e.preventDefault();
            handleCloseReplaceModal();
          }}
        >
          <img
            src={closeIcon}
            className={`transition w-4 h-4`}
            alt="close icon"
          />
        </button>
        <div className="mb-8 font-bold text-md">Create team</div>
        <div>
          <label className="grid gap-4">
            <span className="opacity-50">Enter the team name</span>
            <div className="flex flex-col items-center justify-between gap-6 sm:flex-row j">
              <div className="relative w-full h-full">
                <input
                  placeholder="Put your team name here"
                  value={name}
                  onChange={handleEmailChanged}
                  className="w-full h-[56px] sm:h-[64px] pr-[141px] font-medium border border-black rounded-sm"
                  type="text"
                />
              </div>

              <CustomLink
                type="submit"
                clickableButton={true}
                mode="dark"
                disabled={!name || isLoading}
                className="sm:w-fit"
              >
                Create
              </CustomLink>
            </div>
          </label>
        </div>
        {isSuccess !== null && (
          <>
            {!isSuccess && (
              <p className="text-[#FF5454] mt-4 font-avenir text-sm">
                Error. Maybe you created too many teams?
              </p>
            )}
            {isSuccess && (
              <p className="text-[#0BA693] mt-4 font-avenir text-sm">
                Team created!
              </p>
            )}
          </>
        )}
      </form>
    </Modal>
  );
}

CreateTeamModal.propTypes = {
  showReplaceModal: PropTypes.bool.isRequired,
  handleCloseReplaceModal: PropTypes.func.isRequired,
};
