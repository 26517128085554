import React from "react";
import copyIcon from "../../resources/images/copy-icon.svg";

export const CopyInput = ({ name, link }) => {
  return (
    <div className="h-[48px] md:h-[56px] text-[14px] md:textbase border rounded border-bgColor grid grid-cols-[auto_1fr_58px] justify-between items-center">
      <span className="uppercase md:pl-7 pl-4 text-gray mr-[25px] relative after:content-[''] after:bg-gray after:absolute after:right-[-12px] after:w-[1px] after:h-[24px]">
        {name}
      </span>
      <input
        className="text-ellipsis whitespace-nowrap overflow-hidden border-0 text-sm p-0"
        type="text"
        readOnly
        value={link}
      />
      <button
        onClick={() => {
          navigator.clipboard.writeText(link);
        }}
        className="w-full h-full md:pr-7 pr-4 md:py-4 py-3 transition hover:scale-[1.2] focus:outline-none focus:scale-[1.2] active:scale-100"
      >
        <img className="ml-auto" src={copyIcon} alt="copy icon" />
      </button>
    </div>
  );
};
