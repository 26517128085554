/* global BigInt */
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

export default function UnsaveProjectModal({
  showUnsaveModal,
  handleCloseUnsaveModal,
  handleBackUnsaveModal,
}) {
  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showUnsaveModal}
      onHide={handleCloseUnsaveModal}
      centered
      contentClassName="h-[100vh] text-white items-center justify-center flex"
    >
      <div className="bg-black text-center rounded p-7 max-w-[335px]">
        <div className="mb-4 text-lg font-bold">Unsaved changes</div>

        <p className="mb-8 opacity-50">
          Are you sure you want to close project editing without saving?
        </p>
        <div className="flex gap-4">
          <button
            onClick={handleBackUnsaveModal}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF33]"
          >
            go back
          </button>
          <button
            onClick={handleCloseUnsaveModal}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF66]"
          >
            yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

UnsaveProjectModal.propTypes = {
  showUnsaveModal: PropTypes.bool.isRequired,
  handleBackUnsaveModal: PropTypes.func.isRequired,
  handleCloseUnsaveModal: PropTypes.func.isRequired,
};
