import axios from "axios";
import { removeCachedSelectedTeamUser } from "../teams/teams";

axios.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    if (error.response.status === 404) {
      if (error.response.data?.error === "team not found") {
        return removeCachedSelectedTeamUser();
      }
    }
    return Promise.reject(error);
  }
);
