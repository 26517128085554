import React, { useState, createContext, useContext } from "react";

import { OrderDetailsModal } from "./OrderDetailsModal";
import { useSearchParams } from "react-router-dom";

const initalState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
  hidden: true,
};

const OrderDetailsContext = createContext(initalState);
export const useOrderDetailsContext = () => useContext(OrderDetailsContext);

export const OrderDetails = ({ children }) => {
  // The query params in the URL
  const [searchParams, setSearchParams] = useSearchParams();
  // The internal store
  const [store, setStore] = useState({ modalProps: {}, hidden: true });

  const modalProps = store?.modalProps;

  const showModal = (modalProps) => {
    setStore({
      ...store,
      modalProps,
      hidden: false,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalProps: {},
      hidden: true,
    });

    // Delete tmp stripe params
    const newSearchParams = searchParams;
    newSearchParams.delete("stripe");
    newSearchParams.delete("diagonal");
    setSearchParams(newSearchParams);
    // Reload page to show activated new plan
    window.location.reload(false);
  };

  const renderComponent = () => {
    if (store.hidden) {
      return null;
    }

    return (
      <section
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex cursor-not-allowed items-center justify-center bg-[rgba(0,0,0,.2)] max-h-[100%] py-0 md:py-[20px]"
        onClick={() => {
          // TODO: Jump or something to make user aware that closing the modal is only possible with the Cancel Button.
        }}
      >
        <div
          className="max-h-full overflow-hidden h-full rounded-0 md:rounded-[44px] cursor-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <OrderDetailsModal {...modalProps} />
        </div>
      </section>
    );
  };

  return (
    <OrderDetailsContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </OrderDetailsContext.Provider>
  );
};
