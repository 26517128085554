import checkIcon from "../../resources/images/check-icon.svg";
import closeIcon from "../../resources/images/close-icon.svg";
import { CustomLink } from "../Link/CustomLink";
import { useOrderDetailsContext } from "../OrderModal/OrderDetails";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";

export const ComparisonFullTable = (props) => {
  const {
    className,
    pricingPlans,
    currentId,
    currentPlan,
    managePlan,
    replaceWallet,
    isCorePlan,
    waitingForRedirect,
    planSmallerThanMyPlan,
    ...tableProps
  } = props;

  const { showModal: showBuyPlanModal } = useOrderDetailsContext();
  const showOrderModal = (plan) => {
    showBuyPlanModal({ plan: plan });
  };

  return (
    <table
      {...tableProps}
      className={`w-full table-fixed border-collapse ${className && className}`}
    >
      <thead>
        <tr className="leading-6">
          <td></td>
          {pricingPlans?.map((plan) => (
            <th
              key={plan.name}
              scope="col"
              className={`font-avenir font-medium uppercase`}
            >
              {plan.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="relative top-[45px] ">
        {pricingPlans[0].advantages.map((advantage, index) => (
          <tr
            key={advantage.name}
            className={`h-[60px] ${
              pricingPlans[0].advantages.length - 1 !== index && "border-[1px]"
            } border-l-[0] border-r-[0] border-t-[0] border-b-lightGray leading-4`}
          >
            <th scope="row" className="w-[30%] py-[20px] text-left font-normal">
              {advantage.name}
            </th>
            {pricingPlans?.map((plan) =>
              plan.advantages[index].name === advantage.name ? (
                <th
                  key={plan.name + plan.price}
                  scope="col"
                  className={`font-semibold uppercase`}
                >
                  {typeof advantage.value === "boolean" ? (
                    plan.advantages[index].value ? (
                      <img
                        className="mx-auto"
                        src={checkIcon}
                        alt="check icon"
                      />
                    ) : (
                      <img
                        className="mx-auto"
                        src={closeIcon}
                        alt="close icon"
                      />
                    )
                  ) : (
                    plan.advantages[index].value
                  )}
                </th>
              ) : null
            )}
          </tr>
        ))}
        <tr className="">
          <th scope="row"></th>
          {pricingPlans?.map((plan, i) => (
            <th
              key={plan.name}
              className="relative top-[30px] xl:top-[60px] font-medium"
            >
              <div className="mx-[10px]">
                {plan.id === currentPlan.planId ? (
                  <CustomLink
                    clickableButton={true}
                    onClick={() => {
                      managePlan(plan.originalPlan)();
                    }}
                    mode="azure"
                    className="w-full xl:px-[34px] xl:py-[22px]  xl:text-base"
                    disabled={
                      isCorePlan(plan.originalPlan)() || waitingForRedirect
                    }
                  >
                    <span>
                      {isCorePlan(plan.originalPlan)()
                        ? "Forever Free!"
                        : "manage plan"}
                    </span>
                  </CustomLink>
                ) : (
                  <CustomLink
                    clickableButton={true}
                    onClick={() => {
                      if (plan.isExpired) {
                        // Resubscribe flow? Maybe on the Manage Plan Button????
                      } else if (
                        planSmallerThanMyPlan(plan.originalPlan, currentPlan)
                      ) {
                        // Downgrade flow
                      } else {
                        // Subscribe flow
                        showOrderModal(plan.originalPlan);
                      }
                    }}
                    mode={
                      plan.isExpired
                        ? "dark"
                        : planSmallerThanMyPlan(plan.originalPlan, currentPlan)
                        ? "gray"
                        : "dark"
                    }
                    className="w-full xl:px-[34px] xl:py-[22px] xl:text-base"
                    disabled={waitingForRedirect}
                  >
                    <div className="flex justify-middle">
                      {waitingForRedirect && (
                        <LoadingSpinner
                          fillColorClassName="fill-gray"
                          text=""
                        />
                      )}
                      <span>
                        {plan.isExpired
                          ? "Resubscribe"
                          : planSmallerThanMyPlan(
                              plan.originalPlan,
                              currentPlan
                            )
                          ? "downgrade"
                          : "upgrade"}
                      </span>
                    </div>
                  </CustomLink>
                )}
              </div>
            </th>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
