import React from "react";

export const Switch = (props) => {
  const { type, className, ...inputProps } = props;

  return (
    <label className="toggle-switch md:w-[110px] md:h-[54px] w-[60px] h-[30px]">
      <input {...inputProps} type="checkbox" className={`${className || ""}`} />
      <span className="switch" />
    </label>
  );
};
