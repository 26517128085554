import React from "react";

export const Progress = ({
  title,
  desc,
  currentValue,
  maxValue,
  valuePrefix,
}) => {
  const minWidth = Math.max(Math.ceil((currentValue / maxValue) * 100), 10);

  return (
    <div className="p-5 md:p-7 rounded bg-white font-avenir flex flex-col justify-between">
      <div className="flex items-center justify-between mb-8">
        <div className="text-[14px] md:text-md font-medium">{title}</div>
        <div className="text-[12px] md:text-base text-gray">{desc}</div>
      </div>
      <div className="relative md:h-[56px] h-[40px]">
        {/* <progress
          className="progress w-full"
          max={maxValue}
          value={currentValue}
        /> */}
        <div className="w-full bg-bgColor rounded-full">
          <div
            className="progress md:h-[56px] h-[40px] bg-black rounded-full dark:bg-blue-500"
            style={{ width: `${minWidth}%` }}
          ></div>
        </div>
        <div className="flex text-[12px] md:text-base items-center justify-between left-6 right-6 top-0 bottom-0 absolute ">
          <span className="text-white">
            {Number.parseInt(currentValue.toString()).toLocaleString("en-US")}{" "}
            {valuePrefix}
          </span>
          <span className="text-gray">
            {Number.parseInt(maxValue.toString()).toLocaleString("en-US")}{" "}
            {valuePrefix}
          </span>
        </div>
      </div>
    </div>
  );
};
