/* global BigInt */
import React from "react";
import { CustomLink } from "../Link/CustomLink";
import checkIcon from "../../resources/images/check-icon.svg";
import clsoeIcon from "../../resources/images/close-icon.svg";
import { useOrderDetailsContext } from "../OrderModal/OrderDetails";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";

export const PricingPlanItem = (props) => {
  const {
    name,
    postfix,
    id,
    plan,
    advantages,
    isAnnual,
    isExpired,
    isCurrent,
    better,
    activeTab,
    managePlan,
    replaceWallet,
    isCorePlan,
    waitingForRedirect,
  } = props;

  const planPrice = BigInt(plan.rate_per_month) / BigInt(10) ** BigInt(6);

  const { showModal: showBuyPlanModal } = useOrderDetailsContext();
  const showOrderModal = () => {
    showBuyPlanModal({ plan: plan });
  };

  return (
    <div
      className={`relative ${id !== activeTab ? "hidden" : ""} ${
        isCurrent && "z-10"
      } mx-auto rounded w-full p-5 md:px-[10px] 3xl:p-[36px] md:block bg-white h-fit`}
    >
      <div
        className={`rounded-[44px] ${
          isCurrent ? "bg-azure" : isExpired ? "bg-black" : "bg-bgColor"
        } mb-[40px] p-[30px] text-center`}
      >
        <h3
          className={`mb-[16px] font-avenir font-medium uppercase ${
            isExpired ? "text-white" : ""
          }`}
        >
          {name}
        </h3>
        <div
          className={`mb-[16px] flex items-center justify-center font-bold ${
            isExpired ? "text-white" : ""
          }`}
        >
          <span className="mr-[8px] block text-[20px]">$</span>
          <div className="flex text-xl leading-4">
            {planPrice.toString(10)}
            {postfix && postfix}
          </div>
        </div>
        <div className="mb-[40px] text-gray">Per month</div>
        {isCurrent ? (
          <div className="grid gap-6">
            <CustomLink
              clickableButton={true}
              onClick={() => {
                managePlan();
              }}
              className={`py-[17px] px-[24px] md:py-[20px] uppercase rounded-[32px] md:rounded-[24px]`}
              mode="white"
              disabled={isCorePlan() || waitingForRedirect}
            >
              <div className="flex justify-middle">
                {waitingForRedirect && (
                  <LoadingSpinner fillColorClassName="fill-gray" text="" />
                )}
                <span>{isCorePlan() ? "Forever Free!" : "manage plan"}</span>
              </div>
            </CustomLink>
            {!isCorePlan() && (
              <CustomLink
                clickableButton={true}
                onClick={() => {
                  replaceWallet();
                }}
                className={`py-[17px] px-[24px] md:py-[20px] uppercase rounded-[32px] md:rounded-[24px]`}
                mode="outlinedTransparent"
                disabled={waitingForRedirect}
              >
                <div className="flex justify-middle">
                  {waitingForRedirect && (
                    <LoadingSpinner fillColorClassName="fill-gray" text="" />
                  )}
                  <span>Replace Wallet</span>
                </div>
              </CustomLink>
            )}
          </div>
        ) : (
          <CustomLink
            clickableButton={true}
            onClick={() => {
              if (isExpired) {
                // Resubscribe flow? Maybe on the Manage Plan Button????
                showOrderModal();
              } else if (better) {
                // Subscribe flow
                showOrderModal();
              } else {
                // Downgrade flow
              }
            }}
            className={`py-[17px] px-[24px] md:py-[20px] uppercase rounded-[32px] md:rounded-[24px]`}
            mode={isExpired ? "dark" : better ? "dark" : "gray"}
            disabled={waitingForRedirect}
          >
            <div className="flex justify-middle">
              {waitingForRedirect && (
                <LoadingSpinner fillColorClassName="fill-gray" text="" />
              )}
              <span>
                {isExpired ? "Resubscribe" : better ? "upgrade" : "downgrade"}
              </span>
            </div>
          </CustomLink>
        )}
      </div>
      {advantages.map((advantage) => (
        <li
          key={advantage.name + name}
          className="flex justify-between p-4 border-b border-lightGray last:border-none"
        >
          <div className="max-w-[180px]">{advantage.name}</div>
          <div className="font-semibold">
            {typeof advantage.value === "boolean" ? (
              advantage.value ? (
                <img src={checkIcon} alt="check icon" />
              ) : (
                <img src={clsoeIcon} alt="close icon" />
              )
            ) : (
              advantage.value
            )}
          </div>
        </li>
      ))}
    </div>
  );
};
