import { Constants } from "../../constants";
import axios from "axios";

export function getSignatureRequest() {
  return axios
    .get(`${Constants.BACKEND_URL}/users/signatureRequest`)
    .then((data) => data.data);
}

export function loginUser(walletAddress, signature) {
  return axios
    .post(
      `${Constants.BACKEND_URL}/users`,
      JSON.stringify({
        wallet_address: walletAddress,
        original_signature: signature,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => data.data);
}

export function loginUserGoogle(googleToken) {
  return axios
    .post(
      `${Constants.BACKEND_URL}/users`,
      JSON.stringify({
        google_signin_token: googleToken,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => data.data);
}

export function loginUserGoogleOAuth(code) {
  return axios
    .post(
      `${Constants.BACKEND_URL}/users`,
      JSON.stringify({
        google_signin_oauth_code: code,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => data.data);
}

export function getLoggedInUser() {
  return axios
    .get(`${Constants.BACKEND_URL}/users/me`)
    .then((data) => data.data);
}
