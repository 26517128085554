/* global BigInt */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { CustomLink } from "../Link/CustomLink";
import closeIcon from "../../resources/images/close-icon.svg";
import CreatableSelect from "react-select/creatable";
import { createProject } from "../../services/projects/projects";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function CreateProjectModal({
  showCreateModal,
  handleCloseCreateModal,
}) {
  const [name, setName] = useState("");
  const [rulesInputValue, setRulesIInputValue] = useState("");
  const [rules, setRules] = useState([]);
  const [firewallInputValue, setFirewallIInputValue] = useState("");
  const [firewall, setFirewall] = useState([]);

  function resetValues() {
    setName("");
    setRulesIInputValue("");
    setRules([]);
    setFirewallIInputValue("");
    setFirewall([]);
  }

  const handleRulesKeyDown = (event) => {
    if (!rulesInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setRules((prev) => [...prev, createOption(rulesInputValue)]);
        setRulesIInputValue("");
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleFirewallKeyDown = (event) => {
    if (!firewallInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setFirewall((prev) => [...prev, createOption(firewallInputValue)]);
        setFirewallIInputValue("");
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  function createProjectClicked() {
    createProject({
      name: name,
      description: "Description of this project",
      corsOrigins: rules.map((rule) => rule.value),
      allowedIps: firewall.map((rule) => rule.value),
      bannedIps: "",
    })
      .then((newProject) => {
        resetValues();

        handleCloseCreateModal();
      })
      .catch((error) => {
        handleCloseCreateModal();
      });
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showCreateModal}
      onHide={handleCloseCreateModal}
      centered
      contentClassName="h-[100vh] text-black items-center justify-center flex"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white relative overflow-hidden sm:rounded text-sm sm:text-base p-7 w-full h-full sm:h-fit sm:max-w-[616px]"
      >
        <button
          type="button"
          className="absolute top-6 sm:top-4 right-4 rounded-[16px] transition flex items-center justify-center h-[44px] w-[48px] bg-[#f3f3f3]"
          onClick={handleCloseCreateModal}
        >
          <img
            src={closeIcon}
            className={`transition w-4 h-4`}
            alt="close icon"
          />
        </button>
        <div className="grid gap-4 sm:gap-8">
          <div className="text-base font-bold sm:text-md">
            Create new project
          </div>

          <label className="grid gap-4">
            <span className="opacity-50">Name</span>
            <div className="flex flex-col items-center justify-between gap-6 sm:flex-row j">
              <div className="relative w-full h-full">
                <input
                  value={name}
                  placeholder="Enter a name here"
                  onChange={handleNameChange}
                  className="w-full placeholder:text-sm sm:placeholder:text-base placeholder:opacity-50 h-[56px] sm:h-[64px] font-medium border border-black rounded-sm"
                  type="text"
                />
              </div>
            </div>
          </label>
          <label className="grid gap-4">
            <span className="opacity-50">CORS Rule (optional)</span>
            <div className="flex flex-col items-center justify-between gap-6 overflow-hidden sm:flex-row">
              <div className="relative w-full h-full leading-4 sm:leading-6 multi-select">
                <CreatableSelect
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      border: state.isFocused
                        ? "1px solid #2563eb"
                        : "1px solid black",
                      cursor: "text",
                      ":hover": {
                        border: "1px solid #2563eb",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      opacity: "0.5",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0",
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      padding: "8px 12px 8px 6px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      fontWeight: "500",
                      color: "inherit",
                      padding: 0,
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: "4px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      },
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `pl-3 font-medium transition-none h-[52px] sm:h-[64px] ${
                        state.hasValue
                          ? "py-[7px] sm:py-[10px]"
                          : "py-[11px] sm:py-[15px]"
                      }`,
                  }}
                  components={components}
                  inputValue={rulesInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setRules(newValue)}
                  onInputChange={(newValue) => setRulesIInputValue(newValue)}
                  onKeyDown={handleRulesKeyDown}
                  placeholder="Put your rules "
                  value={rules}
                />
              </div>
            </div>
          </label>
          <label className="grid gap-4">
            <span className="opacity-50">IP Firewall (optional)</span>
            <div className="flex flex-col items-center justify-between gap-6 overflow-hidden sm:flex-row">
              <div className="relative w-full h-full leading-4 sm:leading-6 multi-select">
                <CreatableSelect
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      border: state.isFocused
                        ? "1px solid #2563eb"
                        : "1px solid black",
                      cursor: "text",
                      ":hover": {
                        border: "1px solid #2563eb",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      opacity: "0.5",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0",
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      padding: "8px 12px 8px 6px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0 0",
                      fontWeight: "500",
                      color: "inherit",
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: "4px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      },
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `pl-3 font-medium transition-none h-[52px] sm:h-[64px] ${
                        state.hasValue
                          ? "py-[7px] sm:py-[10px]"
                          : "py-[11px] sm:py-[15px]"
                      }`,
                  }}
                  components={components}
                  inputValue={firewallInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setFirewall(newValue)}
                  onInputChange={(newValue) => setFirewallIInputValue(newValue)}
                  onKeyDown={handleFirewallKeyDown}
                  placeholder="Add IP address   "
                  value={firewall}
                />
              </div>
            </div>
          </label>
          <CustomLink
            type="submit"
            clickableButton={true}
            onClick={createProjectClicked}
            mode="dark"
            disabled={!name}
            className="w-full"
          >
            Create
          </CustomLink>
        </div>
      </form>
    </Modal>
  );
}

CreateProjectModal.propTypes = {
  showCreateModal: PropTypes.bool.isRequired,
  handleCloseCreateModal: PropTypes.func.isRequired,
};
