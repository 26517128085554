/* global BigInt */
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { removeUserFromTeam } from "../../services/teams/teams";

export default function LeaveTeamModal({
  showReplaceModal,
  handleCloseReplaceModal,
  team,
  shouldReload,
}) {
  function leaveClicked() {
    removeUserFromTeam({ teamId: team.id, teamUserId: team.TeamUser?.id })
      .then((success) => {
        handleCloseReplaceModal();
        shouldReload();
      })
      .catch((error) => {
        handleCloseReplaceModal();
        shouldReload();
      });
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showReplaceModal}
      onHide={handleCloseReplaceModal}
      centered
      contentClassName="h-[100vh] text-white items-center justify-center flex"
    >
      <div className="bg-black text-center rounded p-7 max-w-[335px]">
        <div className="mb-4 text-lg font-bold">Leave the team?</div>

        <p className="mb-8 opacity-50">
          Are you sure you want to log out from your team?
        </p>
        <div className="flex gap-4">
          <button
            onClick={handleCloseReplaceModal}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF33]"
          >
            cancel
          </button>
          <button
            onClick={leaveClicked}
            className="py-3 font-avenir textsm flex-1 font-medium uppercase  rounded-[20px] bg-[#FFFFFF66]"
          >
            Leave
          </button>
        </div>
      </div>
    </Modal>
  );
}

LeaveTeamModal.propTypes = {
  showReplaceModal: PropTypes.bool.isRequired,
  handleCloseReplaceModal: PropTypes.func.isRequired,
};
