import React, { useEffect } from "react";
import { CustomLink } from "../Link/CustomLink";
import fireIcon from "../../resources/images/fire-icon.svg";
import logoutIcon from "../../resources/images/icon-logout.svg";
import { useAccount } from "wagmi";
import { SignOut } from "../../services/toolbox/SignOut";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import useToken from "../App/useToken";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import supportIcon from "../../resources/images/support-icon.svg";
import telegramIcon from "../../resources/images/telegram-icon.svg";

import { Constants } from "../../constants";
import {
  acceptTeamInvitation,
  cachedSelectedTeamUser,
  declineTeamInvitation,
  getMyPlan,
  getTeamUsers,
  getTeams,
  setCachedSelectedTeamUser,
} from "../../services/teams/teams";
import { TeamDropdown } from "../Team/TeamDropdown";
import { TeamItem } from "../Team/TeamItem";
import CreateTeamModal from "../Team/CreateTeamModal";
import { TeamList } from "../Team/TeamList";

export const Header = (props) => {
  const { token, setToken } = useToken();

  const [accountButtonHovering, setAccountButtonHovering] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [myPlan, setMyPlan] = useState();

  const [teamUsers, setTeamUsers] = useState([]);

  const [allTeams, setAllTeams] = useState([]);

  const location = useLocation();

  useEffect(() => {
    Promise.all([getMyPlan(), getTeamUsers(), getTeams()]).then((data) => {
      setMyPlan(data[0]);
      setTeamUsers(data[1]);
      setAllTeams(data[2]);
    });
  }, [setMyPlan, setTeamUsers]);

  function reloadTeamUsers() {
    getTeamUsers().then((users) => {
      setTeamUsers(users);
    });
  }

  // **** TEAMS ****

  function reloadAllTeams() {
    Promise.all([getTeams()]).then((data) => {
      setAllTeams(data[0]);
    });
  }

  function acceptedTeams(ofTeams) {
    return ofTeams.filter((team) => {
      return team.TeamUser?.accepted === true;
    });
  }

  function handleTeamSwitch(team) {
    setCachedSelectedTeamUser({ teamUser: team.TeamUser });
  }

  function isCurrentTeam(team) {
    return team.id === cachedSelectedTeamUser()?.Team.id;
  }

  function firstInvitedTeam(teamsToCheck) {
    const invitedTeams = teamsToCheck.filter((team) => {
      return team.TeamUser?.accepted !== true;
    });

    return invitedTeams[0];
  }

  function declineTeamClicked(team) {
    declineTeamInvitation({ teamId: team.id })
      .then((success) => {
        reloadAllTeams();
      })
      .catch((error) => {
        reloadAllTeams();
      });
  }

  function acceptTeamClicked(team) {
    acceptTeamInvitation({ teamId: team.id })
      .then((success) => {
        reloadAllTeams();
      })
      .catch((error) => {
        reloadAllTeams();
      });
  }

  // **** END TEAMS ****

  const {
    open: openWeb3Modal,
    close: closeWeb3Modal,
    isOpen: web3ModalIsOpen,
  } = useWeb3Modal();

  // Google Analytics Page View
  React.useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
    });
  }, [location]);

  // Logout
  const { address: connectedAddress } = useAccount({
    onDisconnect: () => {
      if (!token) {
        return;
      }

      SignOut();
    },
  });
  useEffect(() => {
    if (!token) {
      return;
    }
    const jwtBody = JSON.parse(atob(token.split(".")[1]));
    const walletAddress = jwtBody["wallet_address"];

    if (!walletAddress) {
      // Do not log out on wallet connect for email logins because we might want to take payment with Crypto
      return;
    }

    if (
      connectedAddress &&
      `${connectedAddress}`.trim().toLowerCase() !==
        `${walletAddress}`.trim().toLowerCase()
    ) {
      // Someone is signed in and connected another account
      SignOut();
    }
  }, [token, connectedAddress]);

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const isCryptoLogin = () => {
    if (!token) {
      return false;
    }

    const jwtBody = JSON.parse(atob(token.split(".")[1]));
    const walletAddress = jwtBody["wallet_address"];

    if (walletAddress) {
      return true;
    }

    return false;
  };
  const jwtWalletAddress = () => {
    const jwtBody = JSON.parse(atob(token.split(".")[1]));
    return jwtBody["wallet_address"];
  };
  const cryptoSignOut = () => {
    // Show Web3Modal to disconnect manually.
    openWeb3Modal();
  };

  const accountButtonText = (hovering) => {
    if (hovering) {
      return "Sign Out";
    } else {
      const jwtBody = JSON.parse(atob(token.split(".")[1]));
      const walletAddress = jwtBody["wallet_address"];
      if (walletAddress) {
        return `${walletAddress.match(/^0x.{4}/)}...${walletAddress.match(
          /.{4}$/
        )}`;
      }

      const alternativeButtonText = jwtBody["email"] || "";

      return alternativeButtonText;
    }
  };

  const shortAccountButtonText = (hovering) => {
    if (hovering) {
      return "Sign Out";
    } else {
      const jwtBody = JSON.parse(atob(token.split(".")[1]));
      const walletAddress = jwtBody["wallet_address"];
      if (walletAddress) {
        return `${walletAddress.match(/^0x.{4}/)}...${walletAddress.match(
          /.{4}$/
        )}`;
      }

      const alternativeButtonText = jwtBody["email"] || "";

      return `${alternativeButtonText.match(/^.{4}/) || ""}...${
        alternativeButtonText.match(/.{4}$/) || ""
      }`;
    }
  };

  return (
    <header className="flex items-center pt-2 pb-6 border border-t-0 xs:justify-between xxl:pb-8 border-b-lightGray border-x-0">
      <CreateTeamModal
        showReplaceModal={isCreateModalOpen}
        handleCloseReplaceModal={handleCloseCreateModal}
      />
      <div className="flex flex-col w-full xs:space-x-4 xs:w-fit xs:flex-row xxl:space-x-7">
        <CustomLink
          className="text-gray whitespace-nowrap flex items-center px-3 py-3 xxl:px-7 xxl:py-5 text-[14px] xxl:text-base"
          mode="gray"
          to="/plans"
          noActiveChange={true}
        >
          <span className="font-normal capitalize">Your plan </span>
          <span className="ml-1 text-black capitalize">
            {myPlan?.plan?.name || "Core"}
          </span>
        </CustomLink>
        <CustomLink
          className="text-gray whitespace-nowrap flex items-center px-3 py-3 xxl:px-7 xxl:py-5 text-[14px] xxl:text-base"
          mode="gray"
          to={`mailto:${Constants.SALES_EMAIL}`}
          external={true}
        >
          <span className="font-normal capitalize">The Graph</span>
          <span className="ml-1 text-black capitalize">Unactivated</span>
        </CustomLink>
      </div>
      <div
        className={`${
          props.isTeamsOpen ? "block xxl:hidden" : "hidden"
        } fixed top-[85px] overflow-auto z-20 bg-bgColor right-0 bottom-0 left-0 xxl:static p-4 xxl:p-0`}
      >
        <div className="xxl:flex grid gap-6 overflow-auto flex-col left-0 top-[108px] bottom-0 right-0 bg-bgColor">
          <TeamList
            handleOpenCreateModal={handleOpenCreateModal}
            acceptedTeams={acceptedTeams}
            allTeams={allTeams}
            isCurrentTeam={isCurrentTeam}
            handleTeamSwitch={handleTeamSwitch}
            shouldReload={reloadAllTeams}
            firstInvitedTeam={() => {
              return firstInvitedTeam(allTeams);
            }}
            declineTeamClicked={declineTeamClicked}
            acceptTeamClicked={acceptTeamClicked}
          />
        </div>
      </div>
      <div
        className={`${
          props.isMenuOpen ? "block" : "hidden xxl:block"
        } fixed top-[85px] z-20 bg-bgColor overflow-auto xxl:overflow-visible right-0 bottom-0 left-0 xxl:static p-4 xxl:p-0`}
      >
        <div className="pb-6 space-x-0 space-y-6 border-b xxl:border-b-0 border-b-lightGray xxl:pb-0 xxl:space-x-7 xxl:space-y-0 xxl:flex">
          <TeamDropdown users={teamUsers} shouldReload={reloadTeamUsers} />

          {!isCryptoLogin() && (
            <button
              className="w-full xl:w-fit active:bg-white hover:bg-bgColor transition border border-white focus:bg-bgColor bg-[#f6f6f6] flex itens-center py-4 px-5 md:px-7 md:py-5 rounded-sm"
              mode="gray"
              onClick={() => SignOut()}
            >
              <img className="mr-2" src={logoutIcon} alt="link icon" />
              <span className="hidden xxl:block">
                {shortAccountButtonText(accountButtonHovering)}
              </span>
              <span className="block overflow-hidden xxl:hidden text-ellipsis whitespace-nowrap">
                {accountButtonText(accountButtonHovering)}
              </span>
            </button>
          )}
          {isCryptoLogin() && (
            <button
              className="w-full xxl:w-fit active:bg-white hover:bg-bgColor transition border border-white focus:bg-bgColor bg-[#f6f6f6] flex itens-center py-4 px-5 md:px-7 md:py-5 rounded-sm"
              mode="gray"
              onClick={() => cryptoSignOut()}
            >
              <img className="mr-2" src={logoutIcon} alt="link icon" />
              <span
                style={{ textTransform: "none" }}
                className="truncate max-h-[20px]"
              >
                {`${jwtWalletAddress().substring(
                  0,
                  4
                )}...${jwtWalletAddress().slice(-4)}`}
              </span>
            </button>
          )}

          <CustomLink
            className="w-full xxl:w-[176px] text-center py-3 px-5 md:px-7 md:py-5"
            mode="dark"
            icon={fireIcon}
            to="/plans"
            noActiveChange={true}
          >
            upgrade
          </CustomLink>
        </div>
        <ul className="grid grid-cols-2 gap-4 my-6 font-medium xxl:hidden font-avenir">
          <li>
            <CustomLink
              className="justify-center px-5 py-3 capitalize md:justify-auto md:px-7 md:py-5"
              to={`mailto:${Constants.SUPPORT_EMAIL}`}
              external={true}
              mode="white"
              icon={supportIcon}
            >
              Support
            </CustomLink>
          </li>
          <li>
            <CustomLink
              className="justify-center px-5 py-3 capitalize md:justify-auto md:px-7 md:py-5"
              to={Constants.TELEGRAM_GROUP_URL}
              external={true}
              mode="white"
              icon={telegramIcon}
            >
              Channel
            </CustomLink>
          </li>
        </ul>
        <div className="px-6 py-5 my-auto text-center bg-white rounded xxl:hidden font-avenir">
          <div className="mb-3 text-base font-medium md:text-md">
            Upgrade to Pro
          </div>
          <p className="text-[14px] max-w-[158px] mx-auto md:text-base text-gray mb-6">
            Get 1 month free and unlock all features
          </p>
          <CustomLink
            className="px-5 py-3 md:px-7 md:py-5"
            mode="dark"
            icon={fireIcon}
            to="/plans"
            noActiveChange={true}
          >
            upgrade
          </CustomLink>
        </div>
      </div>
    </header>
  );
};
