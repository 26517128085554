import React, { useRef } from "react";
import Select, { components } from "react-select";
import arrowIcon from "../../resources/images/arrow-icon.svg";
import checkIcon from "../../resources/images/check-icon.svg";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img width={15} height={9} src={arrowIcon} alt="arrow icon" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between px-7">
        <span>{props.children}</span>
        {props.isSelected ? <img src={checkIcon} alt="check icon" /> : null}
      </div>
    </components.Option>
  );
};

export const CustomSelect = ({
  options,
  onChange,
  value,
  className,
  customHeight,
}) => {
  const selectRef = useRef();

  if (!options) return null;

  return (
    <Select
      ref={selectRef}
      isSearchable={false}
      options={options}
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
      className={`text-[14px] md:text-base ${className ? className : ""}`}
      components={{ DropdownIndicator, Option }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused
            ? "rgba(117, 235, 221, 0.50)"
            : "#75ebdd",
          borderRadius: 20,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(117, 235, 221, 0.50)",
          },
          "@media only screen and (max-width: 768px)": {
            ...baseStyles["@media only screen and (max-width: 768px)"],
            padding: "12px 16px",
            height: `${customHeight || "44"}px`,
          },
          height: customHeight || 56,
          padding: "16px 28px",
          border: "none",
          boxShadow: "transparent",
          fontFamily: "Avenir, sans-serif",
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: "none",
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingTop: "0",
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          transform:
            selectRef.current && selectRef.current.props.menuIsOpen
              ? "rotate(180deg)"
              : "",
          color: "#000000",
          padding: 0,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          borderRadius: 32,
          overflow: "hidden",
          border: "2px solid #75EBDD",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
          padding: 0,
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          padding: "15px 0",
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          fontFamily: "Avenir, sans-serif",
          cursor: "pointer",
          backgroundColor: state.isFocused ? "#F2F2F2" : "transparent",
          color: "black",
        }),
      }}
    />
  );
};
