import React, { useState, useEffect } from "react";
import { getLoggedInUser } from "../../services/users/users";
import { getMyPlan } from "../../services/teams/teams";
import { getCurrentMonthlyStatistics } from "../../services/userMonthlyStatistics/userMonthlyStatistics";
import { getProjects } from "../../services/projects/projects";
import GoogleAdsConversionCall from "./GoogleAdsConversionCall";
import { getCurrentActivePayment } from "../../services/userPayments/userPayments";
import { Progress } from "../Progress/Progress";
import { CustomLink } from "../Link/CustomLink";
import { CustomSelect } from "../Select/CustomSelect";
import { CopyInput } from "../CopyInput/CopyInput";
import { CustomChart } from "../Chart/Chart";
import {
  getDaysDifference,
  getFormattedChartData,
} from "../../services/toolbox/plans-utils";
import { Constants } from "../../constants";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";
import pen from "../../resources/images/pen-icon.svg";
import CreateProjectModal from "./CreateProjectModal";
import EditProjectModal from "./EditProjectModal";

export default function Dashboard() {
  const [user, setUser] = useState();
  const [projects, setProjects] = useState();
  const [activeProject, setActiveProject] = useState(undefined);
  const [isLoadingView, setLoadingView] = useState(true);
  const [statistics, setStatistics] = useState();
  const [myPlan, setMyPlan] = useState();
  const [activePayment, setActivePayment] = useState(null);

  useEffect(() => {
    Promise.all([
      getLoggedInUser(),
      getCurrentMonthlyStatistics(),
      getMyPlan(),
      getCurrentActivePayment(),
      getProjects(),
    ]).then((data) => {
      setUser(data[0]);
      setStatistics(data[1]);
      setMyPlan(data[2]);
      setActivePayment(data[3]);
      setProjects(data[4]);
      if (!activeProject) {
        setActiveProject(data[4][0]);
      }

      setLoadingView(false);
    });
  }, [activeProject]);

  function reloadProjects(currentlyActiveProject) {
    Promise.all([getProjects()]).then((data) => {
      setProjects(data[0]);

      const isCurrentlyActiveValid = (data[0] || []).filter((project) => {
        return project.api_key === currentlyActiveProject.api_key;
      });
      if (isCurrentlyActiveValid[0]) {
        setActiveProject(isCurrentlyActiveValid[0]);
      } else {
        setActiveProject(data[0][0]);
      }
    });
  }

  // ---- Plan Helpers ----

  const planFromDate = () => {
    const startOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );

    return activePayment ? new Date(activePayment.from_date) : startOfMonth;
  };
  const planFromDateFormatted = () => {
    return planFromDate().toLocaleDateString("en-UK", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };
  const planExpiryDate = () => {
    const endOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    return activePayment ? new Date(activePayment.to_date) : endOfMonth;
  };
  const planExpiryDateFormatted = () => {
    return planExpiryDate().toLocaleDateString("en-UK", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  // ---- End Plan Helpers ----

  const rpcLinks = (project) => {
    return [
      {
        key: "ethereum-mainnet",
        name: "Ethereum Mainnet",
        url: `https://mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "base-mainnet",
        name: "Base Mainnet",
        url: `https://base-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://base-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "polygon-mainnet",
        name: "Polygon Mainnet",
        url: `https://polygon-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://polygon-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "optimism-mainnet",
        name: "Optimism Mainnet",
        url: `https://optimism-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://optimism-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "arbitrum-one",
        name: "Arbitrum One",
        url: `https://arbitrum-one.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://arbitrum-one.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "bsc-mainnet",
        name: "BSC Mainnet",
        url: `https://bsc-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://bsc-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
      {
        key: "zklink-nova-mainnet",
        name: "zkLink Nova Mainnet",
        url: `https://zklink-nova-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
        wsUrl: `wss://zklink-nova-mainnet.${Constants.RPC_BASE_URL}/${project?.api_key}`,
      },
    ];
  };

  const selectOptions = (project) => {
    return rpcLinks(project).map((link) => ({
      value: link.key,
      label: link.name,
    }));
  };
  const [option, setOption] = useState(selectOptions(activeProject)[0]);

  const formattedChartData = getFormattedChartData({
    name: myPlan?.plan?.name,
    statistics,
  });

  const statisticsStartEndDays = () => {
    const today = new Date();

    return {
      day_start:
        statistics?.day_start ||
        new Date(today.getFullYear(), today.getMonth(), 1),
      day_end:
        statistics?.day_end ||
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
    };
  };

  // ---- Create Project Modal ----

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);

    reloadProjects(activeProject);
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  // ---- Edit Project Modal ----

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);

    reloadProjects(activeProject);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  return (
    <div className="container-fluid w-100">
      {/* Create Project Modal */}
      <CreateProjectModal
        showCreateModal={isCreateModalOpen}
        handleCloseCreateModal={handleCloseCreateModal}
      />

      {/* Edit Project Modal */}
      {activeProject && (
        <EditProjectModal
          showEditModal={isEditModalOpen}
          handleCloseEditModal={handleCloseEditModal}
          project={activeProject}
        />
      )}

      {/* Google Ads Tracking - Conversion Completed */}
      <script src={GoogleAdsConversionCall}></script>

      {/* Loading */}
      {isLoadingView && (
        <div className="flex items-center justify-center w-full h-full">
          <LoadingSpinner fillColorClassName="fill-azure" text="Loading..." />
        </div>
      )}

      {!isLoadingView && (
        <div className="grid w-full gap-7 font-avenir">
          <div className="grid grid-cols-1 sm:grid-cols-[1fr,284px] 3xl:grid-cols-[minmax(471px,1fr)_minmax(214px,1fr)_minmax(471px,1fr)_minmax(214px,1fr)] gap-7">
            <Progress
              title="Requests used"
              desc={`${myPlan?.plan?.requests_per_second} API calls/second`}
              maxValue={myPlan?.plan?.requests_per_month}
              currentValue={myPlan?.requests_used}
            />
            <div className="p-5 md:p-7 shadow-[0px_0px_16px_0px_rgba(117,235,221,0.30)] bg-white rounded flex flex-col justify-between items-center">
              <div className="mb-5 md:mb-[30px] text-base md:text-md font-medium text-center">
                Unlimited API calls/sec
              </div>
              <CustomLink
                to={`mailto:${Constants.SALES_EMAIL}`}
                external={true}
                mode="dark"
                className="w-full text-center"
              >
                activate
              </CustomLink>
            </div>
            <Progress
              title="Plan expiration date"
              desc={planExpiryDateFormatted()}
              maxValue={getDaysDifference(planFromDate(), planExpiryDate()) + 1}
              currentValue={getDaysDifference(planFromDate(), new Date())}
              valuePrefix="days"
            />
            <div className="p-5 md:p-7 shadow-[0px_0px_16px_0px_rgba(117,235,221,0.30)] bg-white rounded flex flex-col justify-between items-center">
              <div className="md:text-md text-base mb-5 md:mb-[30px] font-medium text-center">
                The Graph node
              </div>
              <CustomLink
                to={`mailto:${Constants.SALES_EMAIL}`}
                external={true}
                mode="dark"
                className="w-full text-center"
              >
                activate
              </CustomLink>
            </div>
          </div>
          <div className="grid gap-6 p-5 bg-white rounded md:p-7">
            <div>
              <div className="text-[14px] md:text-md font-medium mb-5 md:mb-8">
                Projects <span className="text-base opacity-50">3</span>
              </div>
              <div className="grid md:grid-cols-[1fr,260px] gap-5 items-center md:justify-between">
                <ul className="flex gap-4 overflow-x-auto overflow-y-hidden md:gap-7">
                  {projects.map((project) => (
                    <li
                      className={`grid text-sm md:text-base items-center gap-3 rounded-[20px] ${
                        activeProject?.api_key === project.api_key
                          ? "bg-bgColor md:pr-7 pr-4 grid-cols-[1fr,16px]"
                          : " opacity-50"
                      }`}
                      key={project.api_key}
                    >
                      <button
                        className={`md:py-4 py-3 font-medium ${
                          activeProject?.api_key === project.api_key
                            ? "pl-4 md:pl-7"
                            : ""
                        } whitespace-nowrap`}
                        onClick={() => setActiveProject(project)}
                      >
                        {project.name}
                      </button>
                      {activeProject?.api_key === project.api_key && (
                        <button
                          onClick={handleOpenEditModal}
                          className="h-full transition min-w-5 hover:scale-125 focus:scale-125 active:scale-100"
                        >
                          <img alt="pen icon" src={pen} />
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
                <CustomLink
                  clickableButton={true}
                  onClick={handleOpenCreateModal}
                  className={`py-4 w-full md:px-[40px] md:w-fit uppercase rounded-[20px]`}
                >
                  create new project
                </CustomLink>
              </div>
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-6">
              <div className="md:min-w-[228px]">
                <CustomSelect
                  options={selectOptions(activeProject)}
                  value={option}
                  onChange={(e) => setOption(e)}
                />
              </div>
              <div className="w-full">
                <CopyInput
                  name="https"
                  link={
                    rpcLinks(activeProject).find(
                      (link) => link.key === option.value
                    ).url
                  }
                />
              </div>
              <div className="w-full">
                <CopyInput
                  name="wss"
                  link={
                    rpcLinks(activeProject).find(
                      (link) => link.key === option.value
                    ).wsUrl
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 md:flex-row border shadow-[0px_0px_16px_0px_rgba(117,235,221,0.30)] border-[rgba(117,235,221,0.50)] items-center justify-between p-5 bg-white rounded md:p-7">
            <div className="flex items-center gap-4">
              <div className="min-w-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_420_574)">
                    <circle cx="12" cy="12" r="12" fill="black" />
                    <path
                      d="M13.2 17L18 12.2M18 12.2L13.2 7M18 12.2L6 12.2"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_420_574">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <p className="text-sm font-medium md:text-md">
                Contact us for more in-depth statistics and access to a
                dedicated Grafana Dashboard
              </p>
            </div>
            <CustomLink
              external={true}
              to={`mailto:${Constants.SALES_EMAIL}`}
              className={`py-4 md:px-[90px] w-full md:w-fit uppercase rounded-[20px]`}
            >
              contact
            </CustomLink>
          </div>
          <div className="p-5 overflow-hidden bg-white rounded md:p-7">
            <div className="flex items-center justify-between mb-8">
              <div className="text-[14px] md:text-md font-medium">
                Global statistics of requests
              </div>
              <time className="text-[12px] md:text-base text-lightGray">
                {new Date().toLocaleDateString("en-GB", {
                  month: "long",
                  year: "numeric",
                })}
              </time>
            </div>
            <div className="md:h-[440px] h-[376px]">
              {formattedChartData && <CustomChart data={formattedChartData} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
