import axios from "axios";
import { SignOut } from "../toolbox/SignOut";

axios.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    if (error.response.status === 401) {
      return SignOut();
    }
    return Promise.reject(error);
  }
);
