import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate, useLocation, Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Plans from "../Plans/Plans";
import Login from "../Login/Login";
import useToken from "./useToken";
import faBolt from "../../resources/icons/fa-bolt.svg";
import faSignOut from "../../resources/icons/fa-right-from-bracket-solid.svg";
import Colors from "../../services/toolbox/Colors";
import { SignOut } from "../../services/toolbox/SignOut";
import { useAccount } from "wagmi";
import PaymentFailure from "./PaymentFailure/PaymentFailure";
import { Header } from "../Header/Header";
import { Constants } from "../../constants";
import { Sidebar } from "../Sidebar/Sidebar";
import { OrderDetails } from "../OrderModal/OrderDetails";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const { token, setToken } = useToken();

  const [accountButtonHovering, setAccountButtonHovering] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTeamsOpen, setIsTeamsOpen] = useState(false);

  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleTeamsMenuToggle = () => {
    setIsTeamsOpen((prev) => !prev);
  };

  // Google Analytics Page View
  React.useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
    });
  }, [location]);

  // Logout
  const { address: connectedAddress } = useAccount({
    onDisconnect: () => {
      if (!token) {
        return;
      }

      SignOut();
    },
  });
  useEffect(() => {
    if (!token) {
      return;
    }
    const jwtBody = JSON.parse(atob(token.split(".")[1]));
    const walletAddress = jwtBody["wallet_address"];

    if (!walletAddress) {
      // Do not log out on wallet connect for email logins because we might want to take payment with Crypto
      return;
    }

    if (
      connectedAddress &&
      `${connectedAddress}`.trim().toLowerCase() !==
        `${walletAddress}`.trim().toLowerCase()
    ) {
      // Someone is signed in and connected another account
      SignOut();
    }
  }, [token, connectedAddress]);

  const isCryptoLogin = () => {
    if (!token) {
      return false;
    }

    const jwtBody = JSON.parse(atob(token.split(".")[1]));
    const walletAddress = jwtBody["wallet_address"];

    if (walletAddress) {
      return true;
    }

    return false;
  };

  // Show Login Screen if no token is set

  if (
    !token ||
    JSON.parse(atob(token.split(".")[1])).exp - new Date().getTime() / 1000 <= 0
  ) {
    return (
      <GoogleOAuthProvider clientId={Constants.GOOGLE_SIGNIN_CLIENT_ID}>
        <Login setToken={setToken} />
      </GoogleOAuthProvider>
    );
  }

  const accountButtonText = (hovering) => {
    if (hovering) {
      return "Sign Out";
    } else {
      const jwtBody = JSON.parse(atob(token.split(".")[1]));
      const walletAddress = jwtBody["wallet_address"];
      if (walletAddress) {
        return `${walletAddress.match(/^0x.{4}/)}...${walletAddress.match(
          /.{4}$/
        )}`;
      }

      const alternativeButtonText = jwtBody["email"] || "";

      return `${alternativeButtonText.match(/^.{4}/) || ""}...${
        alternativeButtonText.match(/.{4}$/) || ""
      }`;
    }
  };

  return (
    <div className="grid grid-cols-1 xxl:grid-cols-[270px_1fr] h-[100vh]">
      <Sidebar
        onMenuClick={handleMenuToggle}
        isMenuOpen={isMenuOpen}
        isTeamsOpen={isTeamsOpen}
        handleTeamsMenuToggle={handleTeamsMenuToggle}
      />

      {/* Main Content */}
      <div className="grid grid-rows-[106px_1fr] xs:grid-rows-[76px_1fr] xxl:grid-rows-[96px_1fr] bg-bgColor h-full xl:p-7 p-4 overflow-y-auto">
        {/* Header */}
        <Header
          onMenuClick={handleMenuToggle}
          isMenuOpen={isMenuOpen}
          isTeamsOpen={isTeamsOpen}
          handleTeamsMenuToggle={handleTeamsMenuToggle}
        />

        <main className="mt-7">
          {/* Payment Alert */}
          <OrderDetails>
            <PaymentFailure />
          </OrderDetails>

          {/* Routes and main content */}
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route
              path="/plans"
              element={
                <OrderDetails>
                  <Plans />
                </OrderDetails>
              }
            ></Route>

            {/* Default */}
            <Route
              path="/"
              element={<Navigate to="/dashboard"></Navigate>}
            ></Route>
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
