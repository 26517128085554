import { ethers } from "ethers";
import { Constants } from "../../constants";
import axios from "axios";
import { teamIdOrDefault } from "../teams/teams";

export function getCurrentActivePayment({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(
        `${Constants.BACKEND_URL}/teams/${teamId}/userPayments/currentActive`
      )
      .then((data) => data.data);
  });
}

export function startStripeCheckoutSession({ plan, teamId }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/userPayments/startStripeSession`,
        JSON.stringify({
          planId: plan.id,
          teamId: teamId,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function getStripeCustomerPortalUrl({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(
        `${Constants.BACKEND_URL}/userPayments/getStripeCustomerPortalUrl?teamId=${teamId}`
      )
      .then((data) => data.data);
  });
}

export function switchToDifferentPlan({ plan, teamId }) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/userPayments/switchToDifferentPlan`,
        JSON.stringify({
          planId: plan.id,
          teamId: teamId,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function createCryptoSubscription({
  teamId,
  plan,
  chainId,
  tokenAddress,
  idempotencyKey,
  fromAddress,
  approval,
}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/userPayments/createCryptoSubscription`,
        JSON.stringify({
          teamId: teamId,
          planId: plan.id,
          chainId: chainId,
          tokenAddress: tokenAddress,
          idempotencyKey: idempotencyKey,
          fromAddress: fromAddress,
          approval: approval,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function getLatestOverdueInvoice({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .get(
        `${Constants.BACKEND_URL}/userPayments/latestOverdueInvoice?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function retryCryptoSubscriptionPayment({ teamId } = {}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/userPayments/retryCryptoSubscriptionPayment`,
        JSON.stringify({
          teamId: teamId,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}

export function setCryptoSubscriptionApproval({
  teamId,
  chainId,
  tokenAddress,
  approval,
}) {
  return teamIdOrDefault({ teamId: teamId }).then((teamId) => {
    return axios
      .post(
        `${Constants.BACKEND_URL}/userPayments/setCryptoSubscriptionApproval`,
        JSON.stringify({
          teamId: teamId,
          chainId: chainId,
          tokenAddress: tokenAddress,
          approval: approval,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data);
  });
}
