/* global BigInt */
import React, { useState, useEffect } from "react";
import { getPlans } from "../../services/plans/plans";
import { getMyPlan } from "../../services/teams/teams";
import { getLoggedInUser } from "../../services/users/users";
import { getCurrentActivePayment } from "../../services/userPayments/userPayments";
import { Switch } from "../Switch/Switch";
import { PricingPlanItem } from "./PricingPlanItem";
import { pricingPlans } from "../../constants";
import { ComparisonFullTable } from "../Table/ComparisonFullTable";
import { ComparisonMiniTable } from "../Table/ComparisonMiniTable";
import { getFormattedPricingPlans } from "../../services/toolbox/plans-utils";
import {
  OrderDetails,
  useOrderDetailsContext,
} from "../OrderModal/OrderDetails";
import {
  getLatestOverdueInvoice,
  getStripeCustomerPortalUrl,
  switchToDifferentPlan,
} from "../../services/userPayments/userPayments";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";
import { useSearchParams } from "react-router-dom";

export default function Plans() {
  // ---- STATE ----
  // The query params in the URL
  const [searchParams, setSearchParams] = useSearchParams();
  // Custom state params
  const [plans, setPlans] = useState();
  const [myPlan, setMyPlan] = useState();
  const [user, setUser] = useState();
  const [activePayment, setActivePayment] = useState();
  const [openInvoice, setOpenInvoice] = useState();

  const [isAnnual, setIsAnnual] = useState(true);
  const [activeTab, setActiveTab] = useState(pricingPlans[0].name);

  // Redirect to external link (e.g. Stripe). Disables Buttons (loading).
  const [waitingForRedirect, setWaitingForRedirect] = useState(false);

  // Show payment dialog
  const { showModal: showBuyPlanModal } = useOrderDetailsContext();

  // Stripe responds with either cancel or success
  useEffect(() => {
    const stripeResponse =
      searchParams.get("stripe") || searchParams.get("diagonal");
    if (stripeResponse && stripeResponse.trim().toLowerCase() === "success") {
      // Delete tmp stripe params
      const newSearchParams = searchParams;
      newSearchParams.delete("stripe");
      newSearchParams.delete("diagonal");
      setSearchParams(newSearchParams);
      // Show success page
      showBuyPlanModal({ isSuccess: true });
    }
  }, [searchParams, showBuyPlanModal, setSearchParams]);

  // Fetch open invoice (to display if replace wallet is clicked)
  useEffect(() => {
    getLatestOverdueInvoice().then((response) => {
      if (response.hasOverdueInvoice) {
        setOpenInvoice(response.invoice);
      }
    });
  }, [setOpenInvoice]);

  const onPricingPlanChange = () => {
    setIsAnnual((prev) => !prev);
  };

  const onActiveTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    Promise.all([
      getPlans(),
      getMyPlan(),
      getLoggedInUser(),
      getCurrentActivePayment(),
    ]).then((data) => {
      setPlans(
        data[0].sort((left, right) => {
          const val =
            BigInt(left.rate_per_month) - BigInt(right.rate_per_month);
          if (val < 0n) {
            return -1;
          } else if (val > 0n) {
            return 1;
          }

          return 0;
        })
      );

      setMyPlan(data[1]);

      setUser(data[2]);

      setActiveTab(data[0][0].id);

      setActivePayment(data[3]);
    });
  }, []);

  // ---- Convenience Functions for Plan subviews ----
  const managePlan = (plan) => () => {
    setWaitingForRedirect(true);

    getStripeCustomerPortalUrl()
      .then((data) => {
        const url = data.url;
        window.location.href = url;
      })
      .catch((err) => {
        // TODO: Show error
        console.log(err);

        setWaitingForRedirect(false);
      });
  };
  const replaceWallet = (plan) => () => {
    // Replace Wallet Flow
    showBuyPlanModal({ amountDue: openInvoice?.amount });
  };
  const isCorePlan = (plan) => () => {
    return BigInt(plan.rate_per_month) === 0n;
  };
  const planSmallerThanMyPlan = (plan, myPlan) => {
    if (!myPlan) {
      return false;
    }
    return BigInt(plan.rate_per_month) < BigInt(myPlan.plan.rate_per_month);
  };

  const formattedPlans = getFormattedPricingPlans(plans);

  // Loading View
  if (!plans || !user) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner fillColorClassName="fill-azure" text="Loading..." />
      </div>
    );
  }

  // ---- VIEW ----
  return (
    <div className="grid gap-[38px] md:gap-[60px]">
      {/* Monthly Switch "hidden" for now */}
      <div className="hidden mx-auto uppercase grid grid-cols-[55px,69px,69px] xs:grid-cols-3 sm:grid-cols-[83px,111px,83px] items-center justify-center gap-[18px] sm:gap-[28px] font-avenir text-sm font-medium md:grid-cols-[190px,120px,190px] md:gap-[40px] md:text-base">
        <span className="text-right opacity-50">Monthly</span>
        <div className="flex justify-center">
          <Switch checked={isAnnual} onChange={onPricingPlanChange} />
        </div>
        <div className="flex flex-col items-center gap-2 text-left md:flex-row md:gap-[16px]">
          Annual
          <span className="block text-[12px] rounded-[32px] bg-[#75ebdd] px-4 py-[8px] text-center md:px-5 md:text-base">
            30% off
          </span>
        </div>
      </div>

      {/* Plans mt-[32px] to be deleted when monthly switch comes back */}
      <div className="overflow-hidden mt-[24px]">
        <ul className="mb-[24px] grid w-full grid-flow-col gap-4 overflow-x-auto md:hidden">
          {formattedPlans?.map((plan) => (
            <li key={plan.name}>
              <button
                onClick={() => onActiveTabChange(plan.id)}
                className={`rounded px-[20px] py-[12px] transition ${
                  activeTab === plan.id
                    ? "bg-black text-azure"
                    : "bg-white text-lightGray"
                }`}
              >
                {plan.name}
              </button>
            </li>
          ))}
        </ul>
        <div className="grid grid-cols-1 justify-center gap-[20px] md:grid-cols-2 lg:grid-cols-4 4xl:grid-cols-[repeat(auto-fit,minmax(290px,356px))]">
          {formattedPlans?.map((plan, i) => (
            <PricingPlanItem
              plan={plan.originalPlan}
              name={plan.name}
              key={plan.id}
              id={plan.id}
              activeTab={activeTab}
              advantages={plan.advantages}
              isAnnual={false}
              isCurrent={myPlan.planId === plan.id}
              better={!planSmallerThanMyPlan(plan.originalPlan, myPlan)}
              isExpired={false}
              managePlan={managePlan(plan.originalPlan)}
              replaceWallet={replaceWallet(plan.originalPlan)}
              isCorePlan={isCorePlan(plan.originalPlan)}
              waitingForRedirect={waitingForRedirect}
            />
          ))}
        </div>
      </div>

      {/* Comparison Table */}
      {formattedPlans && (
        <div className="rounded bg-white md:p-[60px] md:pb-[130px] p-6">
          <ComparisonFullTable
            currentPlan={myPlan}
            pricingPlans={formattedPlans}
            currentId={Number(myPlan?.plan?.id)}
            className="hidden md:table"
            managePlan={managePlan}
            replaceWallet={replaceWallet}
            isCorePlan={isCorePlan}
            waitingForRedirect={waitingForRedirect}
            planSmallerThanMyPlan={planSmallerThanMyPlan}
          />
          <ComparisonMiniTable
            pricingPlans={formattedPlans}
            className="table md:hidden"
          />
        </div>
      )}
    </div>
  );
}
