/* global BigInt */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { CustomLink } from "../Link/CustomLink";
import arrowIcon from "../../resources/images/arrow-icon.svg";
import closeIcon from "../../resources/images/close-icon.svg";
import {
  TeamUserRole,
  addUserToTeam,
  roleToReadable,
} from "../../services/teams/teams";

export default function InviteTeamModal({
  showReplaceModal,
  handleCloseReplaceModal,
}) {
  const [role, setRole] = useState(TeamUserRole.VIEWER);
  const [email, setEmail] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check wallet or email
    addUserToTeam({ role: role, userSearch: email })
      .then((success) => {
        setEmail("");
        setRole(TeamUserRole.VIEWER);
        setIsSuccess(true);
      })
      .catch((error) => {
        setIsSuccess(false);
      });
  };

  useEffect(() => {
    return () => {
      setIsSuccess(null);
    };
  }, [showReplaceModal]);

  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showReplaceModal}
      onHide={handleCloseReplaceModal}
      centered
      contentClassName="h-[100vh] text-black items-center justify-center flex"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white relative sm:rounded p-7 w-full h-full sm:h-fit sm:max-w-[616px]"
      >
        <button
          className="absolute top-8 right-8"
          onClick={(e) => {
            e.preventDefault();
            handleCloseReplaceModal();
          }}
        >
          <img
            src={closeIcon}
            className={`transition w-4 h-4`}
            alt="close icon"
          />
        </button>
        <div className="mb-8 font-bold text-md">Invite</div>
        <div>
          <label className="grid gap-4">
            <span className="opacity-50">Email or wallet address</span>
            <div className="flex flex-col items-center justify-between gap-6 sm:flex-row j">
              <div className="relative w-full h-full">
                <input
                  placeholder="Your future team member"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="w-full h-[56px] sm:h-[64px] pr-[141px] font-medium border border-black rounded-sm"
                  type="text"
                />
                <div className="absolute top-0 bottom-0 right-0">
                  <button
                    type="button"
                    onClick={handleToggleDropdown}
                    className="flex items-center h-full gap-3 font-avenir pr-7"
                  >
                    {roleToReadable(role)}
                    <img
                      src={arrowIcon}
                      className={`transition w-4 h-4 ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      alt="arrow icon"
                    />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute top-2 z-10 right-2 font-avenir w-[150px] grid gap-5 bg-white p-5 rounded-[20px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)]">
                      <label
                        onClick={undefined}
                        className="flex items-center justify-between"
                      >
                        {roleToReadable(TeamUserRole.EDITOR)}
                        <input
                          onChange={() => {
                            setRole(TeamUserRole.EDITOR);
                            handleToggleDropdown();
                          }}
                          checked={role === TeamUserRole.EDITOR}
                          className="outline-none"
                          type="checkbox"
                        />
                      </label>
                      <label
                        onClick={undefined}
                        className="flex items-center justify-between"
                      >
                        {roleToReadable(TeamUserRole.VIEWER)}
                        <input
                          onChange={() => {
                            setRole(TeamUserRole.VIEWER);
                            handleToggleDropdown();
                          }}
                          checked={role === TeamUserRole.VIEWER}
                          className="outline-none"
                          type="checkbox"
                        />
                      </label>
                    </div>
                  )}
                </div>
              </div>

              <CustomLink
                type="submit"
                clickableButton={true}
                mode="dark"
                disabled={!email}
                className="sm:w-fit"
              >
                Invite
              </CustomLink>
            </div>
          </label>
        </div>
        {isSuccess !== null && (
          <>
            {!isSuccess && (
              <p className="text-[#FF5454] mt-4 font-avenir text-sm">
                This user does not exist. Ask this person to create a Chainnodes
                account first.
              </p>
            )}
            {isSuccess && (
              <p className="text-[#0BA693] mt-4 font-avenir text-sm">
                Invitation sent! The user will receive the invitation in their
                teams list
              </p>
            )}
          </>
        )}
      </form>
    </Modal>
  );
}

InviteTeamModal.propTypes = {
  showReplaceModal: PropTypes.bool.isRequired,
  handleCloseReplaceModal: PropTypes.func.isRequired,
};
