/* global BigInt */
import { useOrderDetailsContext } from "./OrderDetails";
import infoIcon from "../../resources/images/info-icon.svg";
import creditCardIcon from "../../resources/images/credit-card-icon.svg";
import walletIcon from "../../resources/images/wallet-icon.svg";
import web3Icon from "../../resources/images/web3-wallet-icon.svg";
import closeIcon from "../../resources/images/close-icon.svg";
import heart from "../../resources/images/heart.svg";
import { useState, useCallback, useEffect } from "react";
import { Constants } from "../../constants";
import {
  usePublicClient,
  useNetwork,
  useWalletClient,
  useAccount,
} from "wagmi";
import { CustomSelect } from "../Select/CustomSelect";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";
import { getLoggedInUser } from "../../services/users/users";
import { verifyEmail } from "../../services/toolbox/EmailUtils";
import {
  signDaiPermit,
  signERC2612Permit,
} from "../../services/toolbox/EthPermit";
import {
  createCryptoSubscription,
  getCurrentActivePayment,
  retryCryptoSubscriptionPayment,
  setCryptoSubscriptionApproval,
} from "../../services/userPayments/userPayments";
import {
  startDiagonalCheckoutSession,
  startStripeCheckoutSession,
} from "../../services/userPayments/userPayments";
import { editTeam } from "../../services/teams/teams";

const CHARGER_CONTRACT = (chainId) => {
  switch (chainId) {
    case 1:
      return "0x68C1681A4CE8e36B1586E9025aF848a780Bb6bc8";
    case 137:
      return "0x68C1681A4CE8e36B1586E9025aF848a780Bb6bc8";
    case 10:
      return "0x68C1681A4CE8e36B1586E9025aF848a780Bb6bc8";
    case 42161:
      return "0x68C1681A4CE8e36B1586E9025aF848a780Bb6bc8";
    default:
      throw new Error("unsupported network");
  }
};

const ALLOWED_STABLECOINS = {
  // USDT REQUIRES MANUAL APPROVAL. SKIP FOR NOW.
  // {
  //   key: "usdt",
  //   name: "USDT",
  //   approval: "approve",
  //   contracts: {
  //     1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  //     137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  //     10: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  //     42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  //   },
  // },
  1: [
    {
      key: "usdc",
      name: "USDC",
      approval: "erc2612",
      contract: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    {
      key: "dai",
      name: "DAI",
      approval: "dai",
      contract: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    },
  ],
  137: [
    {
      key: "usdc",
      name: "USDC",
      approval: "erc2612",
      contract: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    },
    {
      key: "dai",
      name: "DAI",
      approval: "dai",
      contract: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    },
    {
      key: "usdce",
      name: "USDC.e",
      approval: "erc2612",
      contract: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
  ],
  10: [
    // {
    //   key: "usdc",
    //   name: "USDC",
    //   approval: "erc2612",
    //   contract: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    // },
    {
      key: "dai",
      name: "DAI",
      approval: "erc2612",
      contract: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
  ],
  42161: [
    {
      key: "usdc",
      name: "USDC",
      approval: "erc2612",
      contract: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    },
    {
      key: "dai",
      name: "DAI",
      approval: "erc2612",
      contract: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
  ],
};

export const OrderDetailsModal = ({ plan, isSuccess, amountDue }) => {
  // ---- STATES ----
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(isSuccess);
  const [confirmPaymentLoading, setConfirmPaymentLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("debit");
  const [selectedStablecoinKey, setSelectedStablecoinKey] = useState();
  const { hideModal } = useOrderDetailsContext();

  const { address: walletAddress, isConnected: walletAddressIsConnected } =
    useAccount();
  const publicClient = usePublicClient();
  const { chain: currentChain } = useNetwork();
  const { data: walletClient } = useWalletClient();
  const {
    open: openWeb3Modal,
    close: closeWeb3Modal,
    isOpen: web3ModalIsOpen,
  } = useWeb3Modal();

  const [invoiceEmailValidated, setInvoiceEmailValidated] = useState(true);
  const [isFirst, setIsFirst] = useState(true);
  const [invoiceEmailField, setInvoiceEmailField] = useState();

  // ---- EFFECTS ----

  useState(() => {
    getLoggedInUser().then((user) => {
      setUser(user);
    });
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!invoiceEmailField && isFirst) {
      const defaultEmail = user.invoice_email || user.email || "";
      setInvoiceEmailField(defaultEmail);
    }

    setIsFirst(false);
  }, [invoiceEmailField, setInvoiceEmailField, user, isFirst]);

  // Replace Wallet flows need to be Crypto
  useEffect(() => {
    if (!plan && paymentMethod !== "crypto") {
      setPaymentMethod("crypto");
    }
  }, [plan, paymentMethod]);

  useEffect(() => {
    const allowedStablecoins = ALLOWED_STABLECOINS[currentChain?.id];
    const firstCoin = allowedStablecoins ? allowedStablecoins[0] : undefined;
    setSelectedStablecoinKey({ value: firstCoin?.key, label: firstCoin?.name });

    if (!currentChain) {
      // No error yet.
      setError(undefined);
    } else if (!allowedStablecoins) {
      setError("Unsupported coin or chain. Please switch network/coin.");
    } else {
      setError(undefined);
    }
  }, [currentChain]);

  // ---- HELPERS ----

  const isLoadingView = useCallback(() => {
    return !user;
  }, [user]);

  const selectedStablecoin = useCallback(
    (chainId) => {
      if (!chainId) {
        // Loading, return first of first.
        return ALLOWED_STABLECOINS[1][0];
      }

      const allowedStablecoins = ALLOWED_STABLECOINS[chainId];
      let key = selectedStablecoinKey.value;
      if (!key && allowedStablecoins) {
        key = allowedStablecoins[0]?.key;
      }

      const stablecoin = ALLOWED_STABLECOINS[chainId]?.filter(
        (value) => value.key === key
      )[0];

      return stablecoin;
    },
    [selectedStablecoinKey]
  );

  const selectedCurrency = useCallback(() => {
    if (paymentMethod === "debit") {
      return "USD";
    }

    return selectedStablecoin(currentChain?.id)?.name;
  }, [selectedStablecoin, paymentMethod, currentChain]);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const connectWalletButtonClicked = () => {
    if (walletAddressIsConnected) {
      // Change Network
      openWeb3Modal({ route: "SelectNetwork" });
    } else {
      // Select Wallet
      openWeb3Modal();
    }
  };

  const canContinue = () => {
    if (!invoiceEmailValidated || !invoiceEmailField) {
      return false;
    }

    if (paymentMethod === "crypto") {
      return currentChain && walletAddress && selectedStablecoin();
    }

    return true;
  };

  const planPrice = plan
    ? BigInt(plan.rate_per_month) / BigInt(10) ** BigInt(6)
    : BigInt(amountDue || 0) / BigInt(100);

  const setNewApproval = useCallback(
    (signature) => {
      return setCryptoSubscriptionApproval({
        chainId: currentChain.id,
        tokenAddress: selectedStablecoin(currentChain.id).contract,
        approval: signature,
      }).then((response) => {
        // Retry payment
        retryCryptoSubscriptionPayment();
      });
    },
    [currentChain, selectedStablecoin]
  );

  const createNewSubscription = useCallback(
    (signature) => {
      return createCryptoSubscription({
        plan: plan,
        chainId: currentChain.id,
        tokenAddress: selectedStablecoin(currentChain.id).contract,
        idempotencyKey: `${new Date().getTime()}`,
        fromAddress: walletAddress,
        approval: signature,
      });
    },
    [plan, walletAddress, currentChain, selectedStablecoin]
  );

  const relaySignature = useCallback(
    (signature) => {
      if (!plan) {
        return setNewApproval(signature);
      } else {
        return createNewSubscription(signature);
      }
    },
    [createNewSubscription, setNewApproval, plan]
  );

  // ---- ACTIONS ----

  const handleDebitPaymentButtonTrigger = () => {
    return startStripeCheckoutSession({ plan: plan }).then((data) => {
      const url = data.url;
      window.location.href = url;
    });
  };

  const handleCryptoPaymentButtonTrigger = () => {
    if (selectedStablecoin(currentChain?.id)?.approval === "dai") {
      return signDaiPermit(
        publicClient,
        walletClient,
        selectedStablecoin(currentChain.id).contract,
        walletAddress,
        CHARGER_CONTRACT(currentChain.id)
      )
        .then((signature) => {
          return relaySignature(signature);
        })
        .then((response) => {
          setSuccess(true);
        })
        .catch((err) => {
          throw new Error(
            err?.response?.data?.error?.name ||
              err?.response?.data?.error ||
              `${err}`
          );
        });
    } else if (selectedStablecoin(currentChain?.id)?.approval === "erc2612") {
      return signERC2612Permit(
        publicClient,
        walletClient,
        selectedStablecoin(currentChain.id).contract,
        walletAddress,
        CHARGER_CONTRACT(currentChain.id)
      )
        .then((signature) => {
          return relaySignature(signature);
        })
        .then((response) => {
          setSuccess(true);
        })
        .catch((err) => {
          throw new Error(
            err?.response?.data?.error?.name ||
              err?.response?.data?.error ||
              `${err}`
          );
        });
    } else {
      return new Promise((resolve, reject) => {
        reject("Unsupported coin or chain. Please switch network/coin.");
      });
    }
  };

  const confirmPaymentButtonTriggered = () => {
    // Pay button was clicked. Do something.

    if (!canContinue()) {
      return;
    }

    // First set the email

    setConfirmPaymentLoading(true);

    // Set Invoice Email
    editTeam({ invoiceEmail: invoiceEmailField })
      .then((newTeam) => {
        // All good
        setError(null);

        // Last Check
        if (!newTeam.invoice_email) {
          // Something went wrong
          let err = new Error("invoice email not set");
          throw err;
        }

        // Now handle the payment

        if (paymentMethod === "debit") {
          return handleDebitPaymentButtonTrigger();
        } else {
          return handleCryptoPaymentButtonTrigger();
        }
      })
      .catch((err) => {
        setError(`${err}`);
      })
      .finally(() => {
        setConfirmPaymentLoading(false);
      });
  };

  // ---- VIEW ----

  if (success) {
    return (
      <div className="overflow-auto font-avenir w-[100vw] h-[100vh] md:h-auto md:w-[520px] md:rounded-[44px] bg-white">
        {/* Loading View */}
        {isLoadingView() && (
          <div className="w-full h-full flex justify-center items-middle">
            <LoadingSpinner fillColorClassName="fill-azure" text="Loading..." />
          </div>
        )}

        {/* Normal View */}
        {!isLoadingView() && (
          <>
            <div className="block p-4 ml-auto mr-0 md:my-4 md:mr-4 my:0 w-fit">
              <button
                onClick={hideModal}
                className="w-12 h-11 rounded-[16px] flex items-center justify-center bg-bgColor md:bg-transparent md:w-auto md:h-auto"
              >
                <img
                  className="w-6 h-6 md:w-auto md:h-auto"
                  src={closeIcon}
                  alt="close icon"
                />
              </button>
            </div>
            <div className="flex flex-col items-center justify-center px-8 pb-8 h-[calc(100%-76px)]">
              <div className="flex flex-col items-center px-5 py-10 mb-10 rounded-sm md:px-20 md:mb-8 bg-azure">
                <h3 className="mb-[50px] text-md text-center w-[270px]">
                  Thank you for choosing us! Your payment was successful
                </h3>
                <img className="min-w-[295px]" src={heart} alt="heart icon" />
              </div>
              <div className="flex justify-center gap-3">
                <div className="text-lightGray">Have questions?</div>
                <a
                  className="font-medium"
                  href={`mailto:${Constants.SUPPORT_EMAIL}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {Constants.SUPPORT_EMAIL}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <form className="overflow-auto min-h-full md:min-h-auto max-h-full font-avenir w-[100vw] md:h-auto md:w-[700px] bg-white md:rounded-[44px] p-4 md:p-8">
      {/* Loading View */}
      {isLoadingView() && (
        <div className="w-full h-full flex justify-center items-stretch">
          <LoadingSpinner fillColorClassName="fill-azure" text="Loading..." />
        </div>
      )}

      {/* Normal View */}
      {!isLoadingView() && (
        <>
          <h3 className="sticky justify-between flex items-center mb-2 md:mb-8 font-medium text-md h-[76px] md:h-fit">
            {plan ? "Checkout" : "Replace Wallet"}
            <button
              onClick={hideModal}
              className="w-12 h-11 rounded-[16px] flex items-center justify-center bg-bgColor md:bg-transparent md:w-auto md:h-auto"
            >
              <img
                className="w-6 h-6 md:w-auto md:h-auto"
                src={closeIcon}
                alt="close icon"
              />
            </button>
          </h3>
          <div className="pb-8 mb-7 md:mb-8 border-b-[1px] border-b-lightGray">
            {/* All Errors */}
            {error && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
                role="alert"
              >
                <p className="font-bold">Error</p>
                <p>{error}</p>
              </div>
            )}

            {/* For Replace Wallet Flows, don't display a plan name */}
            {plan && (
              <div className="p-6 mb-8 rounded-sm md:p-10 bg-bgColor">
                <div className="mb-3 text-sm md:mb-4 text-gray md:text-normal">
                  Plan subscription
                </div>
                <div className="flex flex-col justify-between md:flex-row md:items-center md:text-md text-normal">
                  <div className="pb-3 mb-3 border-b md:border-b-none md:pb-0 border-b-lightGray md:border-b-transparent md:mb-0">
                    {plan.name} plan/month
                  </div>
                  <div>
                    {planPrice.toString(10)} {selectedCurrency()}
                  </div>
                </div>
              </div>
            )}
            {/* No payment method switch for replace wallet flows */}
            {plan && (
              <div className="">
                <div className="mb-4 text-sm md:mb-5 text-gray md:text-normal">
                  Payment method
                </div>
                <div className="grid gap-4 text-sm font-medium md:gap-5 md:text-normal">
                  <label className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className="w-12 h-12 bg-bgColor rounded-[50%] flex items-center justify-center">
                        <img src={creditCardIcon} alt="credit card icon" />
                      </div>
                      Debit card
                    </div>{" "}
                    <input
                      type="radio"
                      value={"debit"}
                      checked={paymentMethod === "debit"}
                      onChange={handlePaymentMethodChange}
                    />
                  </label>

                  <label className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className="w-12 h-12 bg-bgColor rounded-[50%] flex items-center justify-center ">
                        <img
                          src={walletIcon}
                          alt="wallet icon"
                          className="color-black"
                        />
                      </div>
                      Crypto wallet
                    </div>{" "}
                    <input
                      type="radio"
                      value={"crypto"}
                      className="color-black"
                      checked={paymentMethod === "crypto"}
                      onChange={handlePaymentMethodChange}
                    />
                  </label>
                </div>
              </div>
            )}
            {paymentMethod === "crypto" && (
              <div className="mt-7 md:mt-9">
                <div className="flex items-center gap-3 mb-5 text-sm">
                  <img className="w-6 h-6" src={infoIcon} alt="info icon" />
                  The approval/permit for USDC is necessary so we can charge you
                  gas-less
                </div>
                {/* Wallet Login Button */}
                <div
                  className={`flex space-x-[0px] md:space-x-[16px] space-y-[16px] md:space-y-[0px] flex-col md:flex-row`}
                >
                  <button
                    type="button"
                    className="disabled:opacity-70 text-sm md:text-normal w-full md:w-[267px] enabled:active:bg-blue enabled:active:text-blue enabled:focus:bg-white enabled:focus:text-blue enabled:hover:bg-white enabled:hover:text-blue border enabled:hover:border-blue enabled:active:border-blue transition h-[64px] font-avenir bg-blue rounded-sm py-[28px] flex items-center justify-center uppercase font-medium text-white"
                    onClick={connectWalletButtonClicked}
                    disabled={web3ModalIsOpen}
                  >
                    <div className="flex max-w-full px-8">
                      {web3ModalIsOpen && (
                        <LoadingSpinner
                          fillColorClassName="fill-gray"
                          text="Loading..."
                        />
                      )}
                      {!web3ModalIsOpen && (
                        <img
                          className="mr-4"
                          src={web3Icon}
                          alt="web3 walletconnect icon"
                        />
                      )}
                      <div className="flex items-center h-[24px]">
                        <span className="max-h-[20px]">
                          {web3ModalIsOpen
                            ? "Connecting..."
                            : !walletAddressIsConnected
                            ? "connect wallet"
                            : `${currentChain?.name}`}
                        </span>
                      </div>
                    </div>
                  </button>
                  {/* Stablecoin Dropdown */}
                  {currentChain && (
                    <CustomSelect
                      options={(ALLOWED_STABLECOINS[currentChain.id] || []).map(
                        (coin) => {
                          return { value: coin.key, label: coin.name };
                        }
                      )}
                      value={selectedStablecoinKey}
                      onChange={(e) => setSelectedStablecoinKey(e)}
                      className={"h-[64px] text-center"}
                      customHeight={64}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mb-8">
            <label>
              <div className="mb-3 text-sm md:mb-4 text-gray md:text-normal">
                Email address to receive payment receipts
              </div>
              <input
                onChange={(event) => {
                  setInvoiceEmailField(event.target.value);
                  setInvoiceEmailValidated(!!verifyEmail(event.target.value));
                }}
                type="email"
                className="w-full py-4 border border-black rounded-sm md:py-5 px-7"
                placeholder="Put your email here"
                value={invoiceEmailField}
              />
            </label>
            {paymentMethod === "debit" && (
              <div className="text-sm mt-7 md:mt-8">
                You will be redirected to Stripe to complete the subscription
                payment via debit card
              </div>
            )}
            {paymentMethod === "crypto" && (
              <div className="text-sm mt-7 md:mt-8">
                We will charge your account {planPrice.toString(10)}{" "}
                {selectedCurrency()} today and then every month unless you
                cancel your plan
              </div>
            )}
          </div>
          <button
            disabled={!canContinue() || confirmPaymentLoading}
            onClick={() => confirmPaymentButtonTriggered()}
            className="disabled:bg-bgColor h-[62px] disabled:text-lightGray text-[14px] w-full md:text-base px-5 py-3 md:px-7 md:py-5 active:bg-black focus:bg-[#00221E] hover:bg-[#00221E] transition font-avenir bg-black rounded-sm flex items-center justify-center uppercase font-medium text-white"
          >
            <div className="flex items-center">
              {confirmPaymentLoading && (
                <LoadingSpinner
                  fillColorClassName="fill-gray"
                  text="Loading..."
                />
              )}
              {paymentMethod === "crypto" ? "approve" : "Continue"}
            </div>
          </button>
        </>
      )}
    </form>
  );
};
