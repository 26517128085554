import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import "./Login.css";
import {
  getSignatureRequest,
  loginUserGoogle,
  loginUser,
  loginUserGoogleOAuth,
} from "../../services/users/users";
import fontLogo from "../../resources/chainnodes-org-font-white.svg";
import iconBlock from "../../resources/icon-block.svg";
import { Constants } from "../../constants";
import { ethers } from "ethers";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import redXIcon from "../../resources/icons/red-x-icon.svg";
import Colors from "../../services/toolbox/Colors";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { useSignMessage } from "wagmi";
import { recoverMessageAddress } from "viem";
import googleIcon from "../../resources/images/google-icon.svg";
import logo from "../../resources/images/logo.svg";
import web3Icon from "../../resources/images/web3-wallet-icon.svg";
import { LoadingSpinner } from "../Utilities/LoadingSpinner";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function Login({ setToken }) {
  // **** Errors
  const [error, setError] = useState();
  const closeError = () => setError(undefined);

  // **** WalletConnect
  const handleLoginWallet = useCallback(
    async (walletAddress, signature) => {
      try {
        let token = await loginUser(walletAddress, signature);
        setToken(token);
      } catch (err) {
        setError(`${err}`);
      }
    },
    [setToken]
  );
  const {
    open: openWeb3Modal,
    close: closeWeb3Modal,
    isOpen: web3ModalIsOpen,
  } = useWeb3Modal();

  const {
    data: signMessageData,
    error: signMessageError,
    isLoading: signMessageIsLoading,
    signMessage,
    signMessageAsync,
    variables: signMessageVariables,
  } = useSignMessage();

  const startSignatureRequest = () => {
    getSignatureRequest()
      .then((toBeSigned) => {
        signMessageAsync({ message: toBeSigned.signatureRequest });
      })
      .catch((err) => {
        setError(`${err}`);
      });
  };

  const { address: walletAddress, isConnected: walletAddressIsConnected } =
    useAccount();

  useEffect(() => {
    const message = signMessageVariables?.message;

    if (message && signMessageData) {
      recoverMessageAddress({
        message: message,
        signature: signMessageData,
      })
        .then((recoveredAddress) => {
          handleLoginWallet(recoveredAddress, signMessageData);
        })
        .catch((err) => {
          setError(`${err}`);
        });
    }
  }, [signMessageData, signMessageVariables?.message, handleLoginWallet]);

  // Sign Message Error
  useEffect(() => {
    if (signMessageError) {
      setError(`${signMessageError}`);
    }
  }, [signMessageError]);

  // **** Google SignIn
  const customLoginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = await loginUserGoogleOAuth(tokenResponse.code);
      setToken(token);
    },
    onError: (error) => {
      console.log(error);
    },
    flow: "auth-code",
  });

  // See https://stackoverflow.com/questions/70993933/why-does-the-sign-in-with-google-button-disappear-after-i-render-it-the-second-t
  // See https://stackoverflow.com/questions/50030311/window-google-is-undefined-in-react
  // const googleSignInDivRef = useRef(null);
  // useEffect(() => {
  //   /* global google */
  //   if (googleSignInDivRef.current) {
  //     const renderGoogleButton = () => {
  //       google.accounts.id.initialize({
  //         client_id: Constants.GOOGLE_SIGNIN_CLIENT_ID,
  //         callback: handleGoogle,
  //       });

  //       google.accounts.id.renderButton(googleSignInDivRef.current, {
  //         type: "icon",
  //         theme: "outline",
  //         size: "large",
  //         text: "continue_with",
  //         shape: "rectangular",
  //       });

  //       // google.accounts.id.prompt();
  //     };

  //     // Wait for the google script to load if necessary. Happens on reloads.
  //     const googleScript = document.getElementById("google-gsi-login-script");
  //     if (window.google) {
  //       try {
  //         renderGoogleButton();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       googleScript.addEventListener("load", () => {
  //         try {
  //           renderGoogleButton();
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       });
  //     }
  //   }
  // }, [handleGoogle, googleSignInDivRef]);

  // **** View

  return (
    <div className="relative bg-bgColor h-[100vh] flex items-center overflow-x-hidden overflow-y-auto">
      <div className="absolute z-[0] left-[50%] translate-x-[-50%] h-[100vh] w-[100vw] xl:w-[100vw] xl:h-[100vw] bottom-[100px] xl:bottom-[40px] rounded-[0_0_50%_50%] bg-white" />
      {/* Chainnodes Logo */}
      <img
        className="lg:hidden absolute w-[40px] h-[40px] top-[20px] left-[20px]"
        src={logo}
        alt="chainnodes logo"
      />

      {/* Errors */}
      <div
        className={
          "flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 right-5 top-5" +
          " " +
          `${error === undefined ? "hidden" : ""}`
        }
        role="alert"
        style={{ position: "absolute", zIndex: 10000000 }}
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
          </svg>
          <span className="sr-only">Error icon</span>
        </div>
        <div className="ml-3 text-sm font-normal">{error}</div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-danger"
          aria-label="Close"
          onClick={closeError}
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>

      {/* End Errors */}

      {/* Disclaimer */}

      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="overflow-auto mx-0 xs:mx-[20px] lg:mx-auto font-avenir lg:font-[Assistant] px-[14px] lg:bg-[transparent] lg:py-[0px] py-[28px] rounded-[36px] lg:px-5 xs:px-7 bg-black relative z-10 flex flex-col justify-content-center lg:max-w-[1400px] max-w-[720px]">
          <div className="flex justify-center align-center mb-[32px] lg:mb-[80px]">
            <p className="text-base text-center lg:text-left leading-[22px] lg:text-2xl 3xl:text-4xl text-gray font-normal lg:font-bold lg:leading-xl 3xl:leading-xxl normal-case lg:uppercase mb-0 tracking-[0.282px]">
              <span className="font-[Assistant] font-bold mx-auto w-fit lg:hidden block mb-4 text-white text-[26px] normal-case">
                Sign in.
              </span>
              <span className="text-inherit lg:text-black">Access the app</span>{" "}
              by simply signing a message to prove your decentralized identity.
              Alternatively, use the one-click Google Sign-In
            </p>
          </div>

          <div className="grid lg:grid-cols-[360px_360px_360px] gap-[24px] xl:gap-[40px] grid-cols-1 grid-rows-[62px_62px_62px] xl:grid-rows-1 lg:grid-rows-auto">
            {/* Wallet Login Button */}
            <button
              type="button"
              onClick={() => openWeb3Modal()}
              disabled={web3ModalIsOpen || signMessageIsLoading}
              className="active:bg-blue active:text-blue focus:bg-white focus:text-blue hover:bg-white hover:text-blue border hover:border-blue active:border-blue transition font-avenir bg-blue rounded py-[28px] flex items-center justify-center uppercase font-medium text-white"
            >
              <div className="flex max-w-full px-8">
                {web3ModalIsOpen && (
                  <LoadingSpinner
                    fillColorClassName="fill-gray"
                    text="Loading..."
                  />
                )}
                {!web3ModalIsOpen && (
                  <img
                    className="mr-4"
                    src={web3Icon}
                    alt="web3 walletconnect icon"
                  />
                )}

                <div className="flex items-center h-[24px]">
                  {walletAddressIsConnected && (
                    <span
                      style={{ textTransform: "none" }}
                      className="truncate max-h-[20px]"
                    >{`${walletAddress.substring(0, 4)}...${walletAddress.slice(
                      -4
                    )}`}</span>
                  )}
                  {!walletAddressIsConnected && (
                    <span className="max-h-[20px] text-[15px] sm:text-normal">
                      {web3ModalIsOpen ? "connecting..." : "connect wallet"}
                    </span>
                  )}
                </div>
              </div>
            </button>
            {/* Web3 Sign In (Sign Message) */}
            {walletAddressIsConnected && (
              <button
                onClick={() => {
                  startSignatureRequest();
                }}
                disabled={signMessageIsLoading}
                className="relative flex items-center justify-center font-medium text-white uppercase transition bg-black border rounded active:bg-black active:border-black hover:border-black active:text-azure focus:bg-white focus:text-black hover:bg-white hover:text-black font-avenir"
              >
                {signMessageIsLoading && (
                  <LoadingSpinner
                    fillColorClassName="fill-gray"
                    text="Loading..."
                  />
                )}
                <span className="h-5">
                  {signMessageIsLoading ? "Loading..." : "Sign In"}
                </span>
              </button>
            )}

            {/* Google Login Button */}
            {!walletAddressIsConnected && (
              <button
                onClick={() => {
                  // Google OAuth Login Flow
                  customLoginWithGoogle();
                }}
                className="relative flex items-center justify-center font-medium text-white uppercase transition bg-black border rounded active:bg-black active:border-black hover:border-black active:text-azure focus:bg-white focus:text-black hover:bg-white hover:text-black font-avenir"
              >
                <img className="mr-4" src={googleIcon} alt="google icon" />
                <span className="h-5">Join with google</span>
              </button>
            )}
            {/* Hidden Google Button for triggering Login */}
            {/* <div
                className="flex items-center mx-auto"
                id="googleSignInButton"
                ref={googleSignInDivRef}
                style={{ display: "none" }}
              ></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
