import { extraAdvantages } from "../../constants";

export const getFormattedPricingPlans = (plans) => {
  return plans?.map((plan) => ({
    originalPlan: plan,
    id: plan.id,
    name: plan.name,
    advantages: [
      {
        name: "Requests per month",
        value: `${+plan.requests_per_month / 1000000} М`,
      },
      { name: "Requests per second", value: plan.requests_per_second },
      ...getExtraAdvantages(plan.name),
    ],
  }));
};

export const getExtraAdvantages = (planName) => {
  return extraAdvantages[planName]?.map((plan) => ({
    name: plan[0],
    value: plan[1],
  }));
};

export const getFormattedChartData = ({ name, statistics }) => {
  if (!statistics) {
    return [
      {
        label: name,
        data: [{ primary: 0, secondary: 0 }],
      },
    ];
  }

  const { day_start, day_end, ...days } = statistics;
  return [
    {
      label: name,
      data: Object.entries(days).map((day, i) => ({
        primary: i + 1,
        secondary: +day[1],
      })),
    },
  ];
};

export const getDaysDifference = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return Math.abs(diffInDays);
};
