import React from "react";
import checkIcon from "../../resources/images/check-icon.svg";
import clsoeIcon from "../../resources/images/close-icon.svg";
import { CustomLink } from "../Link/CustomLink";

export const ComparisonMiniTable = (props) => {
  const { className, pricingPlans, ...tableProps } = props;

  return (
    <table
      {...tableProps}
      className={`w-full table-fixed border-collapse ${className && className}`}
    >
      <thead>
        <tr>
          {pricingPlans.map((plan) => (
            <th
              key={plan.name}
              scope="col"
              className={`text-[10px] font-avenir break-words sm:text-sm font-medium uppercase`}
            >
              {plan.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="relative top-[15px] ">
        {pricingPlans[0].advantages.map((advantage, index) => (
          <React.Fragment key={advantage.name}>
            <tr>
              <th
                scope="row"
                colSpan={pricingPlans.length}
                className="w-[30%] pt-5 text-center font-normal"
              >
                {advantage.name}
              </th>
            </tr>
            <tr
              className={`h-[50px] ${
                pricingPlans[0].advantages.length - 1 !== index &&
                "border-[1px]"
              } border-l-[0] border-r-[0] border-t-[0] border-b-lightGray leading-4`}
            >
              {pricingPlans.map((plan) =>
                plan.advantages[index].name === advantage.name ? (
                  <th
                    key={plan.name + plan.price}
                    scope="col"
                    className={`font-semibold uppercase`}
                  >
                    {typeof advantage.value === "boolean" ? (
                      plan.advantages[index].value ? (
                        <img
                          className="mx-auto"
                          src={checkIcon}
                          alt="check icon"
                        />
                      ) : (
                        <img
                          className="mx-auto"
                          src={clsoeIcon}
                          alt="close icon"
                        />
                      )
                    ) : (
                      plan.advantages[index].value
                    )}
                  </th>
                ) : null
              )}
            </tr>
          </React.Fragment>
        ))}
        {/* Upgrade doesn't make sense as we only have one table */}
        {/* <tr>
          <th className="top-60px relative" colSpan={pricingPlans.length}>
            <div className="flex h-[112px] items-end">
              <CustomLink className="w-full py-[24px]" to="/" mode="dark">
                upgrade
              </CustomLink>
            </div>
          </th>
        </tr> */}
      </tbody>
    </table>
  );
};
