/* global BigInt */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { CustomLink } from "../Link/CustomLink";
import closeIcon from "../../resources/images/close-icon.svg";
import CreatableSelect from "react-select/creatable";
import DeleteProjectModal from "./DeleteProjectModal";
import UnsaveProjectModal from "./UnsaveProjectModal";
import { deleteProject, updateProject } from "../../services/projects/projects";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function EditProjectModal({
  showEditModal,
  handleCloseEditModal,
  project,
}) {
  const [name, setName] = useState();

  const [rulesInputValue, setRulesIInputValue] = useState();
  const [rules, setRules] = useState();

  const [firewallInputValue, setFirewallIInputValue] = useState();
  const [firewall, setFirewall] = useState();

  useEffect(() => {
    setName(project.name);

    setRulesIInputValue("");
    setRules(
      (project.cors_origins || []).map((value) => {
        return { label: value, value: value };
      })
    );

    setFirewallIInputValue("");
    setFirewall(
      (project.allowed_ips || []).map((value) => {
        return { label: value, value: value };
      })
    );
  }, [project]);

  const handleRulesKeyDown = (event) => {
    if (!rulesInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setRules((prev) => [...prev, createOption(rulesInputValue)]);
        setRulesIInputValue("");
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleFirewallKeyDown = (event) => {
    if (!firewallInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setFirewall((prev) => [...prev, createOption(firewallInputValue)]);
        setFirewallIInputValue("");
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // ---- Delete Project Modal ----

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);

    // Now delete the project.
    deleteProject({ apiKey: project.api_key })
      .then((deleted) => {
        handleCloseEditModal(false);
      })
      .catch((error) => {
        handleCloseEditModal(false);
      });
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleBackDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  // ---- Unsave Project Modal ----

  const [isUnsaveModalOpen, setIsUnsaveModalOpen] = useState(false);

  const handleCloseUnsaveModal = () => {
    setIsUnsaveModalOpen(false);
    handleCloseEditModal(false);
  };

  const handleBackUnsaveModal = () => {
    setIsUnsaveModalOpen(false);
  };

  const handleOpenUnsaveModal = () => {
    setIsUnsaveModalOpen(true);
  };

  function editProjectClicked() {
    updateProject({
      apiKey: project.api_key,
      name: name,
      description: "Description of this project",
      corsOrigins: rules.map((rule) => rule.value),
      allowedIps: firewall.map((rule) => rule.value),
      bannedIps: "",
    })
      .then((newProject) => {
        handleCloseEditModal();
      })
      .catch((error) => {
        handleCloseEditModal();
      });
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000080] z-20"
      show={showEditModal}
      onHide={handleCloseEditModal}
      centered
      contentClassName="h-[100vh] text-black items-center justify-center flex"
    >
      <DeleteProjectModal
        showDeleteModal={isDeleteModalOpen}
        handleBackDeleteModal={handleBackDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <UnsaveProjectModal
        handleBackUnsaveModal={handleBackUnsaveModal}
        showUnsaveModal={isUnsaveModalOpen}
        handleCloseUnsaveModal={handleCloseUnsaveModal}
      />
      <form
        onSubmit={handleSubmit}
        className="bg-white relative overflow-hidden sm:rounded text-sm sm:text-base p-7 w-full h-full sm:h-fit sm:max-w-[616px]"
      >
        <button
          type="button"
          className="absolute top-6 sm:top-4 right-4 rounded-[16px] transition flex items-center justify-center h-[44px] w-[48px] bg-[#f3f3f3]"
          onClick={handleOpenUnsaveModal}
        >
          <img
            src={closeIcon}
            className={`transition w-4 h-4`}
            alt="close icon"
          />
        </button>
        <div className="grid gap-4 sm:gap-8">
          <div className="text-base font-bold sm:text-md">Edit project</div>

          <label className="grid gap-4">
            <span className="opacity-50">Name</span>
            <div className="flex flex-col items-center justify-between gap-6 sm:flex-row j">
              <div className="relative w-full h-full">
                <input
                  value={name}
                  placeholder="Enter a name here"
                  onChange={handleNameChange}
                  className="w-full placeholder:text-sm sm:placeholder:text-base placeholder:opacity-50 h-[56px] sm:h-[64px] font-medium border border-black rounded-sm"
                  type="text"
                />
              </div>
            </div>
          </label>
          <label className="grid gap-4">
            <span className="opacity-50">CORS Rule (optional)</span>
            <div className="flex flex-col items-center justify-between gap-6 overflow-hidden sm:flex-row">
              <div className="relative w-full h-full leading-4 sm:leading-6 multi-select">
                <CreatableSelect
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      border: state.isFocused
                        ? "1px solid #2563eb"
                        : "1px solid black",
                      cursor: "text",
                      ":hover": {
                        border: "1px solid #2563eb",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      opacity: "0.5",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0",
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      padding: "8px 12px 8px 6px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0 0",
                      fontWeight: "500",
                      color: "inherit",
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: "4px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      },
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `pl-3 font-medium transition-none h-[52px] sm:h-[64px] ${
                        state.hasValue
                          ? "py-[7px] sm:py-[10px]"
                          : "py-[11px] sm:py-[15px]"
                      }`,
                  }}
                  components={components}
                  inputValue={rulesInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setRules(newValue)}
                  onInputChange={(newValue) => setRulesIInputValue(newValue)}
                  onKeyDown={handleRulesKeyDown}
                  placeholder="Put your rules "
                  value={rules}
                />
              </div>
            </div>
          </label>
          <label className="grid gap-4">
            <span className="opacity-50">IP Firewall (optional)</span>
            <div className="flex flex-col items-center justify-between gap-6 overflow-hidden sm:flex-row">
              <div className="relative w-full h-full leading-4 sm:leading-6 multi-select">
                <CreatableSelect
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      border: state.isFocused
                        ? "1px solid #2563eb"
                        : "1px solid black",
                      cursor: "text",
                      ":hover": {
                        border: "1px solid #2563eb",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      opacity: "0.5",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0",
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "24px",
                      padding: "8px 12px 8px 6px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0 0",
                      fontWeight: "500",
                      color: "inherit",
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: "4px",
                      transition: "all .3s",
                      ":hover": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      },
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `pl-3 font-medium transition-none h-[52px] sm:h-[64px] ${
                        state.hasValue
                          ? "py-[7px] sm:py-[10px]"
                          : "py-[11px] sm:py-[15px]"
                      }`,
                    multiValue: (state) =>
                      "py-[11px] pl-3 sm:py-[15px] font-medium",
                  }}
                  components={components}
                  inputValue={firewallInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setFirewall(newValue)}
                  onInputChange={(newValue) => setFirewallIInputValue(newValue)}
                  onKeyDown={handleFirewallKeyDown}
                  placeholder="Add IP address   "
                  value={firewall}
                />
              </div>
            </div>
          </label>
          <div className="grid gap-4 sm:gap-8 sm:grid-cols-2">
            <CustomLink
              type="submit"
              clickableButton={true}
              mode="gray"
              onClick={handleOpenDeleteModal}
              disabled={!name}
              className="w-full"
            >
              delete project
            </CustomLink>
            <CustomLink
              type="submit"
              clickableButton={true}
              mode="dark"
              disabled={!name}
              onClick={editProjectClicked}
              className="w-full"
            >
              save changes
            </CustomLink>
          </div>
        </div>
      </form>
    </Modal>
  );
}

EditProjectModal.propTypes = {
  showEditModal: PropTypes.bool.isRequired,
  handleCloseEditModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};
